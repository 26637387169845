import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTable } from '@angular/material/table';
import { Collection, Sample, Store } from '@common/core/models';
import { IBitfApiPagination, IBitfPaginatorOptions, IBitfStoreEvent } from '@common/interfaces';
import { AppSessionService, StoreService } from '@web/core/services';
import { EApiCallStateNames, EWebStoreActions, eStoreActions } from '@web/enums';
import { map, startWith } from 'rxjs/operators';
import { IMobileMenuBottomSheetConfig } from '@common/interfaces';
import { ESampleGeneratedBy, ESubscriptionStatus } from '@common/enums/common.enum';

@Component({
  selector: 'ss-search-samples-table',
  templateUrl: './search-samples-table.component.html',
  styleUrls: ['./search-samples-table.component.scss'],
})
export class SearchSamplesTableComponent implements OnInit {
  @Input() samples: Sample[];
  @Input() collection?: Collection;
  @Input() pagination: IBitfApiPagination;
  @Input() apiCallStateName: EApiCallStateNames;
  @Input() shouldShowAddToFavorite = true;
  @Input() shouldShowViewPack = true;
  @Input() shouldShowAiFeedback = false;
  @Input() shouldShowRefreshListButton = false;
  @Input() isLoading = false;
  @Input() showSorting = true;
  @Input() isLoadingMessage = '';

  @Output() refreshList = new EventEmitter();

  displayedColumns: string[] = [];
  selection = new SelectionModel<Sample>(true, []);
  paginatorOptions: IBitfPaginatorOptions;
  mobileMenuBottomSheetConfig: IMobileMenuBottomSheetConfig;
  eSubscriptionStatus = ESubscriptionStatus;
  eSampleGeneratedBy = ESampleGeneratedBy;

  @ViewChild(MatTable) table: MatTable<Sample>;

  get isHandsetLayout() {
    return this.storeService.store.activeBreakpoints.isHandsetLayout;
  }

  constructor(public appSessionService: AppSessionService, public storeService: StoreService) {
    this.storeService
      .selectStore([eStoreActions.BREAKPOINT, EWebStoreActions.TOGGLE_DESKTOP_FILTERS_SIDENAV])
      .pipe(
        takeUntilDestroyed(),
        startWith({ store: this.storeService.store } as IBitfStoreEvent<Store>),
        map(event => event.store)
      )
      .subscribe(store => {
        if (store.activeBreakpoints.isHandsetLayout) {
          this.displayedColumns = ['cover', 'sample', 'actions'];
          this.paginatorOptions = { hidePageSize: true };
        } else {
          const columns = ['bulk', 'cover', 'fileName', 'sample', 'key', 'bpm', 'actions'];
          this.displayedColumns = store.isDesktopFiltersSidenavOpen
            ? columns.filter(column => column !== 'key' && column !== 'bpm')
            : columns;
        }
      });
  }

  ngOnInit(): void {
    this.mobileMenuBottomSheetConfig = {
      shouldShowCoverAndTitle: true,
      shouldShowFavorite: this.shouldShowAddToFavorite,
      shouldShowDownload: true,
      shouldShowViewPack: this.shouldShowViewPack,
      shouldShowCollection: true,
    };
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.samples.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.samples);
  }

  onBulkActionCompleted() {
    this.selection.clear();
  }
}
