<ng-container *ngIf="sample">
  <ng-container *ngIf="!isHandsetLayout">
    <button mat-icon-button
      [matMenuTriggerFor]="menu"
      matTooltip="Actions"
      matTooltipPosition="above"
      matTooltipShowDelay="500">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <ng-container *ngIf="shouldShowViewPack && sample.pack">
        <button mat-menu-item
          (click)="onViewPack()">
          <mat-icon>inventory_2</mat-icon>View pack
        </button>
        <mat-divider>
        </mat-divider>
      </ng-container>

      <ng-container *ngIf="sample?.generatedBy === eSampleGeneratedBy.AI">
        <ss-sample-ai-view-prompt-button class="d-block w-100"
          [sample]="sample"
          [IsInMatMenu]="true"
          [showLabel]="true"></ss-sample-ai-view-prompt-button>
      </ng-container>

      <ss-sample-generate-variation-sound-button class="d-block w-100"
        [sample]="sample"
        [IsInMatMenu]="true"
        [showLabel]="true"></ss-sample-generate-variation-sound-button>

      <ng-container *ngIf="sample?.generatedBy === eSampleGeneratedBy.AI && sample?.aiGeneration.prompt">
        <ss-sample-regenerate-sound-button class="d-block w-100"
          [sample]="sample"
          [IsInMatMenu]="true"
          [showLabel]="true"></ss-sample-regenerate-sound-button>
        <mat-divider>
        </mat-divider>
      </ng-container>

      <ss-sample-collection-button class="d-block w-100"
        [sample]="sample"
        [showLabel]="true"
        [IsInMatMenu]="true"
        [collection]="collection"></ss-sample-collection-button>

    </mat-menu>
  </ng-container>

  <ng-container *ngIf="isHandsetLayout">
    <button mat-icon-button
      matTooltip="Actions"
      matTooltipPosition="above"
      matTooltipShowDelay="500"
      (click)="onOpenMenuMobile()">
      <mat-icon>more_vert</mat-icon>
    </button>
  </ng-container>
</ng-container>