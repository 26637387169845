import { BitfStore } from '@bitf/core/models/bitf-store.model';
import { AiGenerationState, Collection, Sample, User } from '@models';
import { AudioPlayerComponent } from '@web/shared/audio-player/audio-player.component';

// NOTE: default values must be defined in the StoreService, not in the model
// (otherwise the default here will override the super Object.assign())
export class Store extends BitfStore {
  user: User;
  currentSample: Sample;
  samplesList: Sample[];
  audioPlayer: AudioPlayerComponent;
  pinnedCollections: Collection[];
  isDesktopMenuSidenavOpen: boolean;
  isDesktopFiltersSidenavOpen: boolean;
  aiGenerationState = new AiGenerationState();

  constructor(storeData: Partial<Store>) {
    super(storeData);
  }
}
