import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Sample, Tag } from '@common/core/models';

@Component({
  selector: 'ss-sample-title-and-tags',
  templateUrl: './sample-title-and-tags.component.html',
  styleUrls: ['./sample-title-and-tags.component.scss'],
})
export class SampleTitleAndTagsComponent implements OnChanges {
  @Input() sample: Sample;
  @Input() truncateLabel = true;
  @Input() numberOfVisibleTags = 3;
  visibleTags: Tag[] = [];
  invisibleTags: Tag[] = [];
  invisibleTagsString = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sample && changes.sample.currentValue !== changes.sample.previousValue) {
      if (this.sample?.tags?.length) {
        this.visibleTags = this.sample.tags.slice(0, this.numberOfVisibleTags);
        this.invisibleTags = this.sample.tags.slice(this.numberOfVisibleTags, this.sample.tags.length);
        this.invisibleTagsString = this.invisibleTags.map(t => t.label).join(', ');
      }
    }
  }
}
