import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Sample, Store } from '@common/core/models';
import { EWebStoreActions } from '@web/enums';
import { SuperAudioPlayerComponent } from '../super-audio-player/super-audio-player.component';
import { AudioPlayerComponent } from '../audio-player/audio-player.component';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'ss-audio-wave',
  templateUrl: './audio-wave.component.html',
  styleUrls: ['./audio-wave.component.scss'],
})
export class AudioWaveComponent extends SuperAudioPlayerComponent {
  get currentSample(): Sample {
    return this.storeService.store?.currentSample;
  }
  get audioPlayer(): AudioPlayerComponent {
    return this.storeService.store?.audioPlayer;
  }
  get isPlaying(): boolean {
    if (!this.currentSample || !this.audioPlayer) {
      return false;
    }
    return this.currentSample === this.sample && this.audioPlayer.isPlaying;
  }

  constructor(protected injector: Injector) {
    super(injector);
  }

  onPlaySample() {
    if (this.currentSample !== this.sample) {
      // Register for audio player audioReady event
      this.audioPlayer.audioReady$
        .pipe(
          take(1),
          tap(() => {
            this.audioPlayer.onPlaySample();
          })
        )
        .subscribe();

      // Note set the current sample in the store
      this.storeService.setStore((store: Store) => {
        store.currentSample = this.sample;
      }, EWebStoreActions.SET_CURRENT_SAMPLE);
    } else {
      this.audioPlayer.waveSurfer.seekTo(0);
      this.audioPlayer.onPlaySample();
    }
  }
  onPauseSample() {
    this.audioPlayer.onPauseSample();
  }

  loadNewSample() {
    super.disposeWaveSurfer();

    // Extend the default options
    Object.assign(this.waveSurferOptions, {
      progressColor: '#424242',
      interact: false,
      container: this.waveform.nativeElement,
      url: this.parseSampleUrl(),
    });

    this.createWaveSurfer();

    this.attachWaveSurferEvents();
  }

  private attachWaveSurferEvents() {
    this.waveSurfer.on('ready', duration => {
      super.onWaveSurferReady({ duration });
    });
  }
}
