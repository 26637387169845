import { ESubscriptionDuration, ESubscriptionStatus, ESubscriptionType } from '@common/enums/common.enum';
import { SuperModel } from './super-model.model';

export class UserSubscription extends SuperModel {
  id: string;
  label: string;
  userMessage?: string;
  purchasedOn: Date;
  willRenewOn: Date;
  status: ESubscriptionStatus;
  type: ESubscriptionType;
  duration: ESubscriptionDuration;
  showFreeTrialBanner: boolean;

  constructor(data = {}) {
    super(data);

    if (this.purchasedOn) {
      this.purchasedOn = new Date(this.purchasedOn);
    }
    if (this.willRenewOn) {
      this.willRenewOn = new Date(this.willRenewOn);
    }
  }

  get serialised() {
    return {};
  }
}
