import { EAiFeedback, ESampleGeneratedBy, ESampleScale, ESampleType } from '@common/enums/common.enum';
import { SuperModel } from './super-model.model';
import { Tag } from './tag.model';
import { Collection } from './collection.model';
import { Pack } from './pack.model';

export class Sample extends SuperModel {
  label: string;
  tags: Tag[];
  key?: string;
  scale?: ESampleScale;
  bpm?: number;
  sampleFileUrl: string;
  coverUrl: string;
  generatedBy: ESampleGeneratedBy;
  duration: string;
  isFavorite: boolean;
  isDownloaded: boolean;
  isBought: boolean;
  collections?: Collection[];
  packId?: string;
  pack?: Pack;
  isPackSample?: boolean;
  type?: ESampleType;
  aiGeneration?: {
    prompt?: string;
    conditioningPrompt?: string;
    aiFeedback?: EAiFeedback | '';
    generatedAt?: Date;
    aiModelId?: string;
    variationAmount?: number;
  };

  constructor(data = {}) {
    super(data);
    this.coverUrl = this.coverUrl || '/assets/images/covers/default-sample-cover.jpg';

    if (this.tags?.length) {
      this.tags = this.tags.map((tag: Tag) => new Tag(tag));
    }
    if (this.collections?.length) {
      this.collections = this.collections.map((collection: Collection) => new Collection(collection));
    }
    if (this.pack) {
      this.pack = new Pack(this.pack);
    }
  }

  get serialised() {
    const serialisedSample: Partial<Sample> = { isFavorite: this.isFavorite };
    if (this.aiGeneration) {
      serialisedSample.aiGeneration = { aiFeedback: this.aiGeneration.aiFeedback };
    }
    return serialisedSample;
  }
}
