import { Component, HostBinding, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Filter, Store, Tag } from '@common/core/models';
import { SamplesService, StoreService } from '@common/core/services';
import { AppSessionService } from '@web/core/services';
import { EApiCallStateNames, EWebStoreActions } from '@web/enums';
import { IBitfApiRequest, IBitfApiResponse } from '@web/interfaces';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'ss-desktop-filters-sidenav',
  templateUrl: './desktop-filters-sidenav.component.html',
  styleUrls: ['./desktop-filters-sidenav.component.scss'],
})
export class DesktopFiltersSidenavComponent {
  @Input() apiCallStateName: EApiCallStateNames;
  @Input() service: {
    getFilters: (requestParams?: IBitfApiRequest) => Observable<IBitfApiResponse<Filter>>;
    getAutocomplete: (requestParams: IBitfApiRequest) => Observable<IBitfApiResponse<Tag[]>>;
  };

  isOpen = false;

  @HostBinding('class.open')
  get openClass() {
    return this.isOpen;
  }

  @HostBinding('class.close')
  get closeClass() {
    return !this.isOpen;
  }

  @HostBinding('class.player-open')
  get playerActiveClass() {
    return !!this.storeService.store.currentSample;
  }

  constructor(private storeService: StoreService, public appSessionService: AppSessionService) {
    this.storeService
      .selectStore(EWebStoreActions.TOGGLE_DESKTOP_FILTERS_SIDENAV)
      .pipe(
        takeUntilDestroyed(),
        map(x => x.store.isDesktopFiltersSidenavOpen)
      )
      .subscribe(isOpen => {
        this.isOpen = isOpen;
      });

    this.storeService
      .selectStore(EWebStoreActions.TOGGLE_DESKTOP_MENU_SIDENAV)
      .pipe(
        takeUntilDestroyed(),
        map(x => x.store.isDesktopMenuSidenavOpen),
        filter(isOpen => isOpen)
      )
      .subscribe(() => {
        this.onClose();
      });
  }

  onClose = () => {
    this.storeService.setStore((store: Store) => {
      store.isDesktopFiltersSidenavOpen = false;
    }, EWebStoreActions.TOGGLE_DESKTOP_FILTERS_SIDENAV);
  };
}
