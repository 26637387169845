import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { IBitfToastData, IBitfToastMessagesService } from '@interfaces';
import { BitfToastMessagesService } from '../bitf-toast-messages.service';
import { EBitfToastHorizontalPosition, EBitfToastVerticalPosition } from '@enums';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class BitfMatToastMessageService
  extends BitfToastMessagesService
  implements IBitfToastMessagesService
{
  private router: Router = inject(Router);

  constructor(protected matSnackBar: MatSnackBar) {
    super();
  }

  show(data: IBitfToastData) {
    let messageToDisplay = data.title || '';
    if (data.title && data.message) {
      messageToDisplay += ' | ';
    }
    messageToDisplay += data.message || '';

    const options = {
      duration: data.duration || this.defaultToastData.duration,
      horizontalPosition: data.horizontalPosition || EBitfToastHorizontalPosition.CENTER,
      verticalPosition: data.verticalPosition || EBitfToastVerticalPosition.BOTTOM,
    };

    if (data.link) {
      this.matSnackBar
        .open(messageToDisplay, data.link.label, options)
        .onAction()
        .subscribe(() => {
          this.router.navigate(data.link.url, { queryParams: data.link?.queryParams || null });
        });
    } else {
      this.matSnackBar.open(messageToDisplay, null, options);
    }
  }

  clearAllMessages() {}
}
