import {
  BitfMockDbPathBuilder,
  BitfMockDbRequestMapper,
  BitfMockDbResponseMapper,
  BitfMockDbEnvelopeMapper,
} from '@bitf/core/parsers/mock-db-parser';

import { IBitfParserStrategy } from '@interfaces';
import { AppEnvelopeMapper, AppPathBuilder, AppRequestMapper, AppResponseMapper } from '@web/core/parsers';
export const BITF_CONFIGS: any = {
  apiErrorsInterceptorService: { interceptHttpResponsesWithCode: [401, '4XX', '5XX'] },
  toastMessage: { durationMs: 5000 },
  parsers: {
    defaultParser: 'appParser',
    // appParser: {
    //   namespace: '@sintra',
    //   customHeaders: {
    //     Prefer: 'odata.include-annotations=*',
    //   },
    //   uiMessageKey: 'messages',
    //   pathBuilder: new AppPathBuilder(),
    //   requestMapper: new AppRequestMapper(),
    //   responseMapper: new AppResponseMapper(),
    //   envelopeMapper: new AppEnvelopeMapper(),
    // } as IBitfParserStrategy,
    parserStrategies: {
      appParser: {
        pathBuilder: new AppPathBuilder(),
        requestMapper: new AppRequestMapper(),
        responseMapper: new AppResponseMapper(),
        envelopeMapper: new AppEnvelopeMapper(),
      } as IBitfParserStrategy,
      mockDbParser: {
        pathBuilder: new BitfMockDbPathBuilder(),
        requestMapper: new BitfMockDbRequestMapper(),
        responseMapper: new BitfMockDbResponseMapper(),
        envelopeMapper: new BitfMockDbEnvelopeMapper(),
      } as IBitfParserStrategy,
    },
  },
};
