<!-- DESKTOP -->
<div class="row mb-3"
  *ngIf="!isHandsetLayout">

  <div class="col-auto">
    <ss-header-cover [sample]="sample"
      [coverUrl]="coverUrl"
      [altImage]="label"
      [borderColor]="pinColor"></ss-header-cover>
  </div>

  <div class="col">
    <div class="row mb-2">
      <div class="col pl-0">
        <h1 class="label">{{label}}</h1>
      </div>
      <div class="col-auto pl-0">
        <ss-sample-download-button *ngIf="showDownloadButton"
          [pack]="pack"></ss-sample-download-button>

        <button mat-icon-button
          *ngIf="showEditButton"
          class="ml-2"
          (click)="onEdit()">
          <mat-icon>edit</mat-icon>
        </button>

        <button mat-icon-button
          *ngIf="showDeleteButton"
          class="ml-2"
          (click)="onDelete()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col pl-0">
        <p class="m-0 description"
          [ngClass]="{'description--expanded': isDescriptionExpaded}"
          #descriptionRef>{{description}}</p>
      </div>
      <div class="col-auto pl-0"
        *ngIf="isDescriptionTruncated">
        <button *ngIf="!isDescriptionExpaded"
          mat-icon-button
          (click)="onToggleDescriptionExpanded()">
          <mat-icon class="material-icons-outlined">expand_more</mat-icon>
        </button>
        <button *ngIf="isDescriptionExpaded"
          mat-icon-button
          (click)="onToggleDescriptionExpanded()">
          <mat-icon class="material-icons-outlined">expand_less</mat-icon>
        </button>
      </div>
    </div>
  </div>

</div>

<!-- MOBILE -->
<div *ngIf="isHandsetLayout"
  class="bg-cover bg-position-center mx-n3 mt-n3 mb-3"
  [ngStyle]="{'background-image': 'linear-gradient(180deg, rgba(27, 27, 31, 0.37) 0%, #1b1b1f 70.63%), url(' + coverUrl + '?width=820)'}">

  <!-- First row -->
  <div class="row no-gutters align-items-center mb-4 pt-2">
    <div class="col-auto"
      *ngIf="backLinkUrl">
      <button mat-icon-button
        [routerLink]="backLinkUrl">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <div class="col-auto ml-auto">

      <ss-sample-download-button class="mr-3"
        *ngIf="showDownloadButton"
        [pack]="pack"></ss-sample-download-button>

      <button mat-icon-button
        *ngIf="showEditButton || showDeleteButton"
        [matMenuTriggerFor]="moreOptions">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #moreOptions="matMenu">
        <button mat-menu-item
          *ngIf="showEditButton"
          (click)="onEdit()">
          <mat-icon>edit</mat-icon>Edit
        </button>
        <button mat-menu-item
          *ngIf="showDeleteButton"
          (click)="onDelete()">
          <mat-icon>delete</mat-icon>Delete
        </button>
      </mat-menu>
    </div>
  </div>

  <!-- Pin row -->
  <div *ngIf="pinColor || isPinned"
    class="d-flex align-items-center mb-1 px-3">
    <span class="pin mr-2 bg-{{pinColor}}"></span>
    <span class="mat-caption">Pinned</span>
  </div>

  <!-- Play and label row -->
  <div class="row no-gutters flex-nowrap align-items-center px-3 mb-2">
    <div class="col-auto d-flex align-self-start mr-1"
      *ngIf="sample">
      <ss-audio-button [sample]="sample"
        [displayAsFab]="true"></ss-audio-button>
    </div>
    <div class="col trick-min-width-0">
      <h1 class="d-none d-sm-block mb-0 label">{{label}}</h1>
      <h2 class="d-sm-none mb-0 label">{{label}}</h2>
    </div>
  </div>


  <div class="row no-gutters px-3">
    <div class="col">
      <p #descriptionRef
        class="m-0 mat-caption description"
        [ngClass]="{'description--expanded': isDescriptionExpaded}">
        {{description}}
      </p>
    </div>

    <div class="col-auto px-0"
      *ngIf="isDescriptionTruncated">
      <button *ngIf="!isDescriptionExpaded"
        mat-icon-button
        (click)="onToggleDescriptionExpanded()">
        <mat-icon class="material-icons-outlined">expand_more</mat-icon>
      </button>
      <button *ngIf="isDescriptionExpaded"
        mat-icon-button
        (click)="onToggleDescriptionExpanded()">
        <mat-icon class="material-icons-outlined">expand_less</mat-icon>
      </button>
    </div>
  </div>
</div>