<div class="d-flex align-items-center ss-fixed-player mat-elevation-z20"
  [ngClass]="{'closed': !isPlayerExpanded, 'open': isPlayerExpanded, 
  'show-player': storeService.store?.currentSample, 
  'hide-player': !storeService.store?.currentSample}">

  <ss-desktop-audio-player *ngIf="storeService.store.activeBreakpoints.isWebLayout"
    [sample]="currentSample"
    class="col-24 h-100"
    (previousSample)="onPreviousSample()"
    (nextSample)="onNextSample()"></ss-desktop-audio-player>

  <ss-mobile-audio-player *ngIf="storeService.store.activeBreakpoints.isHandsetLayout"
    [sample]="currentSample"
    [(isPlayerExpanded)]="isPlayerExpanded"
    class="col-24"
    (previousSample)="onPreviousSample()"
    (nextSample)="onNextSample()"></ss-mobile-audio-player>
</div>