import { Injectable, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { CommonSharedModule } from '@common/shared/common-shared.module';
import { IBitfSidenavContent } from '@interfaces';
import { BitfMatSidenavGlobalService } from './bitf-mat-sidenav-global.service';
import { IBitfCloseEvent } from '@interfaces';
@Injectable({
  providedIn: CommonSharedModule,
})
export class BitfMatSidenavService {
  constructor(
    private resolver: ComponentFactoryResolver,
    private sidenavGlobalService: BitfMatSidenavGlobalService
  ) {}

  open(sidenavContent: IBitfSidenavContent) {
    sidenavContent.componentFactory = this.resolver.resolveComponentFactory(sidenavContent.component);
    return this.sidenavGlobalService.open(sidenavContent);
  }

  close(event: IBitfCloseEvent<unknown>) {
    this.sidenavGlobalService.close(event);
  }
}
