import { Collection } from './collection.model';
import { Sample } from './sample.model';
import { SuperModel } from './super-model.model';

export class Pack extends SuperModel {
  label: string;
  description?: string;
  author?: string;
  coverUrl?: string;
  isFavorite: boolean;
  isDownloaded: boolean;
  isBought: boolean;
  collections?: Collection[];
  sample?: Sample;
  samples?: Sample[];
  numberOfSamples?: number;

  constructor(data = {}) {
    super(data);
    this.coverUrl = this.coverUrl || '/assets/images/covers/default-pack-cover.jpg';

    if (this.collections?.length) {
      this.collections = this.collections.map((collection: Collection) => new Collection(collection));
    }
    if (this.samples?.length) {
      this.samples = this.samples.map((sample: Sample) => new Sample(sample));
    }
    if (this.sample) {
      this.sample = new Sample(this.sample);
    }
  }

  get serialised() {
    return {};
  }
}
