import { Injectable, Injector } from '@angular/core';
import { jwtDecode } from 'jwt-decode';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { EBitfAuthState } from '@common/enums';
import { IBitfApiResponse, ISampleSoundAuthLogin, ISampleSoundAuthTokenMetadata } from '@common/interfaces';
import { AuthApiService } from '@common/services/api';
import { User } from '../models';
import { BitfAuthService } from '@common/libs/bitforce/core/services/auth/bitf-auth.service';
import { BitfTryCatch } from '../decorators';
import { LoaderService } from './loader.service';
import { BITF_CONFIGS } from '@web/configs';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BitfAuthService<ISampleSoundAuthLogin, ISampleSoundAuthTokenMetadata> {
  constructor(
    protected injector: Injector,
    private authApiService: AuthApiService,
    private loaderService: LoaderService
  ) {
    super(injector);
  }

  static decodeToken(loginResponse: ISampleSoundAuthLogin): ISampleSoundAuthTokenMetadata {
    if (!loginResponse) {
      return undefined;
    }

    const decodedToken: any = jwtDecode(loginResponse.accessToken);
    Object.assign(decodedToken, {
      token: loginResponse.accessToken,
      refreshToken: loginResponse.refreshToken,
      expiresAt: decodedToken.exp * 1000,
      expiresIn: loginResponse.expiresIn * 1000,
      iat: decodedToken.iat * 1000,
      exp: decodedToken.exp * 1000,
    });

    return decodedToken;
  }

  @BitfTryCatch()
  decodeToken(loginResponse: ISampleSoundAuthLogin): ISampleSoundAuthTokenMetadata {
    return AuthService.decodeToken(loginResponse);
  }

  handleAuthentication(requestParams) {
    this.authState$.next(EBitfAuthState.LOGIN_IN_PROGRESS);

    this.authApiService.getToken(requestParams).subscribe(
      (response: IBitfApiResponse<any>) => {
        this.signIn(response.content);
      },
      () => {
        this.onLoginError();
      }
    );
  }

  handleShopifySSOAuthentication(queryParams) {
    if (!queryParams.customer_id || !queryParams.key) {
      return;
    }
    this.authState$.next(EBitfAuthState.LOGIN_IN_PROGRESS);

    this.authApiService.getShopifySSOToken({ body: queryParams }).subscribe(
      (response: IBitfApiResponse<any>) => {
        this.signIn(response.content);
      },
      () => {
        this.onLoginError();
      }
    );
  }

  signOut(): void {
    this.loaderService.show();
    this.authApiService.logout(this.authTokenMetaData.refreshToken).subscribe(() => {
      super.signOut();
    });
  }

  async renewToken(): Promise<ISampleSoundAuthLogin> {
    return new Promise((success, reject) => {
      this.authApiService.refreshToken(this.authTokenMetaData.refreshToken).subscribe(
        (response: IBitfApiResponse<ISampleSoundAuthLogin>) => {
          if (response.content.accessToken) {
            this.signIn({
              ...response.content,
              refreshToken: this.authTokenMetaData.refreshToken,
            });
            success(response.content);
          } else {
            this.signIn(undefined);
            reject();
          }
        },
        err => {
          this.signIn(undefined);
          reject(err);
        }
      );
    });
  }

  listenForAuthStateChange(): Observable<EBitfAuthState> {
    return this.authState$.pipe(
      tap((authState: EBitfAuthState) => {
        if (authState === EBitfAuthState.TOKEN_RETRIEVED) {
          this.usersService.getMe().subscribe(
            (response: IBitfApiResponse<User>) => {
              this.setUser(response.content);
              this.onLoginSuccess();
              this.handleRedirect();
            },
            () => {
              this.onLoginError();
            }
          );
        }
      })
    );
  }

  handleRedirect() {
    const user = this.storeService.store.user;
    const storage = this.storageService.data;
    const redirectUrl = storage.redirectUrl;
    if (redirectUrl) {
      delete storage.redirectUrl;
      this.storageService.setData(storage);
    }

    if (user?.isFirstAccess) {
      this.router.navigate(['onboarding']);
    } else if (redirectUrl) {
      window.location.replace(redirectUrl);
    } else {
      window.location.replace(BITF_CONFIGS.urls.homePageUrl);
    }
  }
}
