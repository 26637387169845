import { Component, Input } from '@angular/core';
import { Sample } from '@common/core/models';
import { ESampleGeneratedBy } from '@common/enums/common.enum';

@Component({
  selector: 'ss-sample-cover',
  templateUrl: './sample-cover.component.html',
  styleUrls: ['./sample-cover.component.scss'],
})
export class SampleCoverComponent {
  @Input() sample: Sample;
  @Input() size = '40px';
  @Input() imageWidth = 40;
  eSampleGeneratedBy = ESampleGeneratedBy;

  get retinaImageWidth() {
    return Math.floor(this.imageWidth * 1.8);
  }
}
