<div class="tag-chip d-flex align-items-center justify-content-center {{cssClasses}}"
  [ngClass]="{'highlighted': amIHighlighted && !disableHighlight, 'has-delete-btn': showDeleteButton || amIHighlighted}"
  (click)="onTagClick($event)">

  <span class="mx-auto text-truncate"
    data-testid="text-content">
    <span *ngIf="amIExcluded">NOT&nbsp;</span>
    {{tag?.label || label}}
  </span>

  <button class="close-btn"
    (click)="onTagRemove($event)"
    mat-icon-button
    *ngIf="showDeleteButton || amIHighlighted"
    [disabled]="isDisabled">
    <mat-icon>close</mat-icon>
  </button>
</div>