import { SuperModel } from './super-model.model';

export class SampleDownload extends SuperModel {
  url: string;

  constructor(data = {}) {
    super(data);
  }

  get serialised() {
    return {};
  }
}
