export enum EApiCallStateNames {
  SAMPLES = 'SAMPLES',
  DOWNLOADS = 'DOWNLOADS',
  FAVORITES = 'FAVORITES',
  COLLECTION_SAMPLES = 'COLLECTION_SAMPLES',
  PACK_SAMPLES = 'PACK_SAMPLES',
  AI = 'AI',
}

export enum EApiRequestPartKeys {
  SORTING = 'SORTING',
  FILTERS = 'FILTERS',
  TAGS = 'TAGS',
  SEARCH = 'SEARCH',
  PAGINATION = 'PAGINATION',
}
