import { SuperModel } from './super-model.model';
import { IAiModel } from '@interfaces';

export class AiSettings extends SuperModel {
  models: IAiModel[];
  infoPanelMessage: string;
  trendingPrompts: string[];

  constructor(data = {}) {
    super(data);
  }

  get serialised() {
    return {};
  }
}
