import { Genre } from './genre.model';
import { Pack } from './pack.model';
import { SuperModel } from './super-model.model';

export class UserSuggestedPack extends SuperModel {
  genre: Genre;
  packs: Pack[];

  constructor(data = {}) {
    super(data);
    if (this.packs?.length) {
      this.packs = this.packs.map((pack: Pack) => new Pack(pack));
    }
    if (this.genre) {
      this.genre = new Genre(this.genre);
    }
  }

  get serialised() {
    return {};
  }
}
