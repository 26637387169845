<div class="ss-keys px-2 py-3"
  (click)="onPanelClick($event)">
  <ss-inline-loader *ngIf="isLoading"
    [showOverlay]="true"
    [hideSpinner]="true"></ss-inline-loader>
  <div class="d-flex justify-content-center h-100">
    <ss-search-samples-key-button [value]="eSampleKey.C_SHARP"
      svg="c-d.svg"
      [selectedFilterValue]="filters?.key"
      (selected)="onKeyChange($event)">
    </ss-search-samples-key-button>
    <ss-search-samples-key-button class="mr-4"
      [value]="eSampleKey.D_SHARP"
      svg="d-e.svg"
      [selectedFilterValue]="filters?.key"
      (selected)="onKeyChange($event)">
    </ss-search-samples-key-button>
    <ss-search-samples-key-button [value]="eSampleKey.F_SHARP"
      svg="f-g.svg"
      [selectedFilterValue]="filters?.key"
      (selected)="onKeyChange($event)">
    </ss-search-samples-key-button>
    <ss-search-samples-key-button [value]="eSampleKey.G_SHARP"
      svg="g-a.svg"
      [selectedFilterValue]="filters?.key"
      (selected)="onKeyChange($event)">
    </ss-search-samples-key-button>
    <ss-search-samples-key-button [value]="eSampleKey.A_SHARP"
      svg="a-b.svg"
      [selectedFilterValue]="filters?.key"
      (selected)="onKeyChange($event)">
    </ss-search-samples-key-button>
  </div>
  <div class="d-flex justify-content-center mt-3">
    <ss-search-samples-key-button [value]="eSampleKey.C"
      label="C"
      [selectedFilterValue]="filters?.key"
      (selected)="onKeyChange($event)">
    </ss-search-samples-key-button>
    <ss-search-samples-key-button [value]="eSampleKey.D"
      label="D"
      [selectedFilterValue]="filters?.key"
      (selected)="onKeyChange($event)">
    </ss-search-samples-key-button>
    <ss-search-samples-key-button [value]="eSampleKey.E"
      label="E"
      [selectedFilterValue]="filters?.key"
      (selected)="onKeyChange($event)">
    </ss-search-samples-key-button>
    <ss-search-samples-key-button [value]="eSampleKey.F"
      label="F"
      [selectedFilterValue]="filters?.key"
      (selected)="onKeyChange($event)">
    </ss-search-samples-key-button>
    <ss-search-samples-key-button [value]="eSampleKey.G"
      label="G"
      [selectedFilterValue]="filters?.key"
      (selected)="onKeyChange($event)">
    </ss-search-samples-key-button>
    <ss-search-samples-key-button [value]="eSampleKey.A"
      label="A"
      [selectedFilterValue]="filters?.key"
      (selected)="onKeyChange($event)">
    </ss-search-samples-key-button>
    <ss-search-samples-key-button [value]="eSampleKey.B"
      label="B"
      [selectedFilterValue]="filters?.key"
      (selected)="onKeyChange($event)">
    </ss-search-samples-key-button>
  </div>
  <div class="mt-3 w-100 d-flex justify-content-center">
    <mat-button-toggle-group class="ss-toggle-group-small w-75"
      (change)="onScaleChange($event)"
      [value]="filters?.scale">
      <mat-button-toggle [value]="eSampleScale.ALL">All</mat-button-toggle>
      <mat-button-toggle [value]="eSampleScale.MAJOR">Major</mat-button-toggle>
      <mat-button-toggle [value]="eSampleScale.MINOR">Minor</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="ss-keys-separator mt-3 mb-3"></div>

  <div class="d-flex w-100 justify-content-between align-items-center">
    <button mat-fab
      data-testid="reset-key-button"
      extended
      color="basic"
      class="ss-button-small"
      (click)="onResetScaleAndKey()">Reset</button>

    <button mat-fab
      extended
      color="primary"
      class="ss-button-small"
      (click)="onClose()">Close</button>
  </div>
</div>