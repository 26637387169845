import { BitfStorage } from '@bitf/core/models/bitf-storage.model';
import { IAiModel } from '@interfaces';

// NOTE: default values must be defined in the StorageService, not in the model
// (otherwise the default here will override the super Object.assign())
export class Storage extends BitfStorage {
  aiLastPrompt?: string;
  aiModel?: IAiModel; // TODO: define the type

  constructor(storageData: Partial<Storage>) {
    super(storageData);
  }
}
