import { ESubscriptionDuration } from '@common/enums/common.enum';
import { SuperModel } from './super-model.model';

export interface ISubscriptionAction {
  label: string;
  shopifyCheckoutUrl?: string;
  apiAction?: string;
  confirmationMessage?: string;
  buttonColor: 'basic' | 'primary' | 'accent' | 'warn';
  userTooltip?: string;
}
interface ISubscriptionDescriptor {
  price: number;
  actions?: ISubscriptionAction[];
  userMessage?: string;
  features: {
    label: string;
    isIncluded: boolean;
  }[];
}

export class Subscription extends SuperModel {
  id: string;
  label: string;

  currencySymbol: string;
  creditsPerMonth: number;
  monthly: ISubscriptionDescriptor;
  yearly: ISubscriptionDescriptor;

  constructor(data = {}) {
    super(data);
  }

  getPrice(duration: ESubscriptionDuration): number {
    return this[duration.toLowerCase()]?.price;
  }
  getActions(duration: ESubscriptionDuration): ISubscriptionDescriptor['actions'] {
    return this[duration.toLowerCase()]?.actions || [];
  }
  getUserMessage(duration: ESubscriptionDuration): string {
    return this[duration.toLowerCase()]?.userMessage;
  }
  getDurationLabel(duration: ESubscriptionDuration): string {
    return duration === ESubscriptionDuration.MONTHLY ? '/monthly' : '/yearly';
  }
  getFeatures(duration: ESubscriptionDuration): ISubscriptionDescriptor['features'] {
    return this[duration.toLowerCase()]?.features || [];
  }

  get serialised() {
    return {
      id: this.id,
    };
  }
}
