import { ESubscriptionDuration, ESubscriptionStatus } from '@common/enums/common.enum';
import { SuperModel } from './super-model.model';
import { UserSettings } from './user-settings.model';
import { UserSubscription } from './user-subscription.model';

export class User extends SuperModel {
  firstName: string;
  lastName: string;
  email: string;
  settings?: UserSettings;
  isFirstAccess?: boolean;
  credits: number;
  fullName: string;
  subscription?: UserSubscription;

  get hasActiveSubscription(): boolean {
    return this.subscription?.status === ESubscriptionStatus.ACTIVE;
  }

  constructor(data = {}) {
    super(data);
    if (this.subscription) {
      this.subscription = new UserSubscription(this.subscription);
    }
    if (this.settings) {
      this.settings = new UserSettings(this.settings);
    }
    this.fullName = `${this.firstName} ${this.lastName}`;
  }

  get serialised() {
    return {
      id: this.id,
      settings: this.settings?.serialised,
      subscription: this.subscription?.serialised,
    };
  }

  // get canViewLogs() {
  //   return true;
  // }
}
