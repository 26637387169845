import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Collection, Pack, Sample } from '@common/core/models';
import { Router } from '@angular/router';
import { AppSessionService } from '@web/core/services';
import { EAiFeedback, ESampleGeneratedBy } from '@common/enums';
import { IMobileMenuBottomSheetConfig } from '@common/interfaces';

@Component({
  selector: 'ss-mobile-sample-menu-bottom-sheet',
  templateUrl: './mobile-sample-menu-bottom-sheet.component.html',
  styleUrls: ['./mobile-sample-menu-bottom-sheet.component.scss'],
})
export class MobileSampleMenuBottomSheetComponent {
  config: IMobileMenuBottomSheetConfig;
  sample: Sample;
  collection: Collection;
  eSampleGeneratedBy = ESampleGeneratedBy;
  eAiFeedback = EAiFeedback;
  @Output() viewPack = new EventEmitter<void>();
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    data: {
      sample: Sample;
      pack?: Pack;
      collection?: Collection;
      config: IMobileMenuBottomSheetConfig;
    },
    private router: Router,
    public appSessionService: AppSessionService,
    private matBottomSheetRef: MatBottomSheetRef<MobileSampleMenuBottomSheetComponent>
  ) {
    this.sample = data.sample;
    this.collection = data.collection;
    this.config = data.config;
  }

  onViewPack() {
    this.router.navigate(['/', 'packs', this.sample.packId, 'search']);
    this.onCloseBottomSheet();
    this.viewPack.emit();
  }

  onCloseBottomSheet() {
    this.matBottomSheetRef.dismiss();
  }
}
