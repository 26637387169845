<button class="ss-key-button"
  data-testid="key-button"
  [class.ss-key-button--selected]="isSelected"
  (click)="onClick($event)">

  <ng-container *ngIf="svg">
    <img [src]="'assets/keys/' + svg" />
  </ng-container>

  <ng-container *ngIf="!svg">
    {{ label }}
  </ng-container>
</button>