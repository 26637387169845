import { EBitfStoreActions } from '@common/enums';
export enum EWebStoreActions {
  SET_CURRENT_SAMPLE = 'SET_CURRENT_SAMPLE',
  UPDATE_CURRENT_SAMPLE = 'UPDATE_CURRENT_SAMPLE',
  SET_SAMPLES_LIST = 'SET_SAMPLES_LIST',
  UPDATE_SAMPLES_LIST = 'UPDATE_SAMPLES_LIST',
  SET_WAVE_SURFER = 'SET_WAVE_SURFER',
  SET_PINNED_COLLECTIONS = 'SET_PINNED_COLLECTIONS',
  TOGGLE_DESKTOP_MENU_SIDENAV = 'TOGGLE_DESKTOP_MENU_SIDENAV',
  TOGGLE_DESKTOP_FILTERS_SIDENAV = 'TOGGLE_DESKTOP_FILTERS_SIDENAV',
  UPDATE_USER = 'UPDATE_USER',
  AI_GENERATION_STATE_UPDATE = 'AI_GENERATION_STATE_UPDATE',
  AI_GENERATION_COMPLETED = 'AI_GENERATION_COMPLETED',
  AI_GENERATION_ERROR = 'AI_GENERATION_ERROR',
  AI_GENERATION_IN_PROGRESS = 'AI_GENERATION_IN_PROGRESS',
}

export const eStoreActions = { ...EBitfStoreActions, ...EWebStoreActions };
