<ng-container *ngIf="IsInMatMenu">
  <button mat-menu-item
    (click)="openViewPromptDialog()">
    <mat-icon>sticky_note_2</mat-icon>
    View Prompt</button>
</ng-container>

<ng-container *ngIf="!IsInMatMenu">
  <button mat-button
    [matTooltip]="!showLabel ? 'View Prompt': ''"
    matTooltipShowDelay="1000"
    class="w-100 justify-content-start"
    (click)="openViewPromptDialog()">
    <mat-icon>sticky_note_2</mat-icon>
    <span *ngIf="showLabel">View Prompt</span></button>
</ng-container>