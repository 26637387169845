import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { BitfFormItemConfig } from '@common/libs/bitforce/core/models';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FiltersService extends BitfApiService {
  constructor(public injector: Injector) {
    super('filters', injector, environment.mockApiUrl);
  }

  getSearchFilters() {
    return this.action<any>({ action: 'search', modelMapper: 'actionData' }).pipe(
      map(response => {
        response.content = new BitfFormItemConfig(response.content.data);
        return response;
      })
    );
  }
}
