<ng-container *ngIf="IsInMatMenu">
  <div class="ai-icon">
    <img src="assets/icons/a.svg"
      alt="A" />
    <img src="assets/icons/i.svg"
      alt="I" />
  </div>
  <button mat-menu-item
    (click)="openViewPromptDialog()">
    <mat-icon>call_split</mat-icon>
    Generate Variation</button>
</ng-container>

<ng-container *ngIf="!IsInMatMenu">
  <div class="ai-icon">
    <img src="assets/icons/a.svg"
      alt="A" />
    <img src="assets/icons/i.svg"
      alt="I" />
  </div>
  <button mat-button
    [matTooltip]="!showLabel ? 'Generate Variation' : ''"
    matTooltipShowDelay="1000"
    class="w-100 justify-content-start"
    (click)="openViewPromptDialog()">
    <mat-icon>call_split</mat-icon>
    <span *ngIf="showLabel">Generate Variation</span></button>
</ng-container>