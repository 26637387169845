import { Component, Input } from '@angular/core';
import { BitfMatSidenavService } from '@common/libs/bitforce/core/services/sidenav/material/bitf-mat-sidenav.service';
import { EBitfSidenavMode, EBitfSidenavPosition } from '@web/enums';
import { InfoSidePanelComponent } from './info-side-panel/info-side-panel.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ss-open-side-panel-button',
  templateUrl: './open-side-panel-button.component.html',
  styleUrls: ['./open-side-panel-button.component.scss'],
})
export class OpenSidePanelButtonComponent {
  @Input() message: string;

  constructor(private bitfMatSidenavService: BitfMatSidenavService, private sanitized: DomSanitizer) {}

  onOpenInfoSidePanel() {
    this.bitfMatSidenavService
      .open({
        component: InfoSidePanelComponent,
        sidenavOptions: {
          mode: EBitfSidenavMode.OVER,
          position: EBitfSidenavPosition.END,
          disableClose: false,
          autoFocus: true,
        },
        componentData: { message: this.sanitized.bypassSecurityTrustHtml(this.message) },
      })
      .subscribe();
  }
}
