import { Sample } from './sample.model';
import { SuperModel } from './super-model.model';

export class Collection extends SuperModel {
  label: string;
  coverUrl?: string;
  description?: string;
  isPinned?: boolean;
  pinColor?: string;
  samples?: Sample[];
  numberOfSamples?: number;

  constructor(data = {}) {
    super(data);
    this.coverUrl = this.coverUrl || '/assets/images/covers/default-collection-cover.jpg';

    if (this.samples?.length) {
      this.samples = this.samples.map((sample: Sample) => new Sample(sample));
    }
  }

  get serialised() {
    return {
      label: this.label,
      description: this?.description,
      isPinned: this?.isPinned,
      pinColor: this?.pinColor,
    };
  }
}
