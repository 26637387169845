<div class="d-flex align-items-center">
  <mat-slider [disabled]="!sample || isLoading"
    [disableRipple]="true"
    [max]="1"
    [min]="0"
    [step]="0.1">
    <input matSliderThumb
      [value]="volume"
      (valueChange)="volumeChange.emit($event)">
  </mat-slider>

  <button mat-icon-button
    *ngIf="showToggleVolumeButton"
    [disabled]="!sample || isLoading"
    (click)="toggleVolume.emit($event)">
    <mat-icon class="material-icons-outlined"
      *ngIf="volume === 0">volume_mute</mat-icon>
    <mat-icon class="material-icons-outlined"
      *ngIf="volume > 0">volume_up</mat-icon>
  </button>
</div>