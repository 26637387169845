<div class="w-100 h-100 d-flex flex-column bg-app text-light bg-sign-in-image">
  <img src="/assets/logo/samplesound-logo-white.svg"
    alt="Samplesound"
    class="samplesound-logo"
    [ngClass]="{'sign-in-form': showSignInForm}">
  <div class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
    [ngClass]="{'justify-content-between': !showSignInForm && storeService.store.activeBreakpoints.isHandset}">
    <div>&nbsp;<!-- logo placeholder form flex distribution --></div>

    <ng-container *ngIf="showSignInSignUp">
      <h2 class="text-center m-0">The Right Sample<br /> with the power of AI</h2>
      <div class="d-flex flex-column">
        <button mat-raised-button
          class="px-5"
          color="primary"
          (click)="showSignInForm=true"
          [ngClass]="{
          'mt-5': !storeService.store.activeBreakpoints.isHandset 
        }">Sign in</button>
        <button mat-raised-button
          class="px-5 mt-3"
          color="primary"
          (click)="onSignUp()"
          [ngClass]="{
          'mb-5': storeService.store.activeBreakpoints.isHandset,
        }">Sign up</button>
      </div>
    </ng-container>

    <!-- Shopify SSO -->
    <ng-container *ngIf="showSSO">
      <h2 class="text-center m-0 mb-5">The Right Sample<br /> with the power of AI</h2>
      <h3 class="text-center">Logging in, please wait...</h3>
      <mat-spinner diameter="20"></mat-spinner>
    </ng-container>

    <!-- Manual login form -->
    <ng-container *ngIf="showSignInForm">
      <div class="sing-in-form-container">
        <form class="d-flex flex-column"
          [formGroup]="form"
          (ngSubmit)="onSignIn()">
          <mat-form-field appearance="outline">
            <mat-label>Username</mat-label>
            <input matInput
              placeholder="Insert your account name or mail"
              formControlName="email">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput
              placeholder="Insert your password"
              formControlName="password"
              [type]="hidePassword ? 'password' : 'text'">
            <mat-icon matSuffix
              (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' :
              'visibility'}}</mat-icon>
          </mat-form-field>

          <button mat-raised-button
            color="primary"
            [disabled]="isLoading"
            class="align-self-end">
            <ng-container *ngIf="!isLoading; else loginInProgress">
              Log in
            </ng-container>
            <ng-template #loginInProgress>
              <mat-spinner diameter="20"></mat-spinner>
            </ng-template>
          </button>
        </form>
      </div>
    </ng-container>
  </div>
</div>