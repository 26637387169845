import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { IBitfApiAction, IBitfApiRequest, IBitfApiResponse } from '@interfaces';
import { Subscription, User, UserSuggestedPack } from '@models';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BitfApiService {
  constructor(public injector: Injector) {
    // super('users', injector, environment.mockApiUrl);
    super('users', injector);
  }

  getMe(): Observable<IBitfApiResponse<User>> {
    return super.request<User>({
      path: '/me',
      method: 'GET',
      disableHideLoader: true,
    });
  }

  getSuggestedPacks(request: IBitfApiRequest): Observable<IBitfApiResponse<UserSuggestedPack[]>> {
    return super.request<UserSuggestedPack[]>({
      ...request,
      relation: 'suggested-packs',
      method: 'GET',
    });
  }

  getSubscriptions(request: IBitfApiRequest): Observable<IBitfApiResponse<Subscription[]>> {
    return super.request<Subscription[]>({
      ...request,
      // apiUrl: environment.mockApiUrl,
      relation: 'subscriptions',
      method: 'GET',
    });
  }

  updateSubscription(request: IBitfApiRequest): Observable<IBitfApiResponse<IBitfApiAction>> {
    return super.action<IBitfApiAction>({
      ...request,
      // apiUrl: environment.mockApiUrl,
      action: 'subscriptions/update',
    });
  }
}
