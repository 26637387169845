import { SuperModel } from './super-model.model';

export class AiGenerate extends SuperModel {
  generationId: string;

  constructor(data = {}) {
    super(data);
  }

  get serialised() {
    return {};
  }
}
