<ng-container *ngIf="this.sample && !this.sample.isFavorite && !this.isBulk && shouldShowAddToFavorite">
  <button mat-icon-button
    *ngIf="!showLabel"
    matTooltip="Add to favorites"
    matTooltipPosition="above"
    matTooltipShowDelay="1000"
    (click)="onAddToFavoriteSample($event)">
    <mat-icon class="text-active">favorite_border</mat-icon>
  </button>
  <button mat-button
    *ngIf="showLabel"
    matTooltip="Add to favorites"
    matTooltipPosition="above"
    matTooltipShowDelay="1000"
    (click)="onAddToFavoriteSample($event)">
    <mat-icon class="text-active">favorite_border</mat-icon>
    Add to favorites
  </button>
</ng-container>

<ng-container *ngIf="this.sample && this.sample.isFavorite && !this.isBulk">
  <button mat-icon-button
    *ngIf="!showLabel"
    matTooltip="Remove from favorites"
    matTooltipPosition="above"
    matTooltipShowDelay="1000"
    (click)="onRemoveFromFavoriteSample($event)">
    <mat-icon class="text-active">favorite</mat-icon>
  </button>
  <button mat-button
    *ngIf="showLabel"
    matTooltip="Remove from favorites"
    matTooltipPosition="above"
    matTooltipShowDelay="1000"
    (click)="onRemoveFromFavoriteSample($event)">
    <mat-icon class="text-active">favorite</mat-icon>
    Remove from favorites
  </button>
</ng-container>



<!-- bulk buttons -->
<button mat-button
  class="bulk-action-button"
  *ngIf="this.isBulk && shouldShowAddToFavorite"
  (click)="onAddToFavoriteBulk($event)">
  <mat-icon>favorite</mat-icon>
  Add to favorites
</button>

<button mat-button
  class="bulk-action-button"
  *ngIf="this.isBulk"
  (click)="onRemoveFromFavoriteBulk($event)">
  <mat-icon>favorite_border</mat-icon>
  Remove from favorites
</button>