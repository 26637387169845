<ng-container *ngIf="sample">
  <button mat-fab
    *ngIf="displayAsFab && !isPlaying"
    color="primary"
    class="mat-elevation-z20 scale-down"
    (click)="onPlaySample($event)">
    <mat-icon class="scale-up">play_arrow</mat-icon>
  </button>
  <button mat-icon-button
    *ngIf="!displayAsFab &&!isPlaying "
    (click)="onPlaySample($event)">
    <mat-icon class="material-icons-outlined">play_arrow</mat-icon>
  </button>

  <button mat-fab
    *ngIf="displayAsFab && isPlaying"
    color="primary"
    class="mat-elevation-z20 scale-down"
    (click)="onPauseSample($event)">
    <mat-icon class="scale-up">pause</mat-icon>
  </button>
  <button mat-icon-button
    *ngIf="!displayAsFab && isPlaying"
    (click)="onPauseSample($event)">
    <mat-icon class="material-icons-outlined">pause</mat-icon>
  </button>
</ng-container>