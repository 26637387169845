import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { Filter, Tag } from '@common/core/models';
import { IBitfApiRequest, IBitfApiResponse } from '@common/interfaces';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FavoritesService extends BitfApiService {
  constructor(public injector: Injector) {
    super('favorites', injector);
    // super('favorites', injector, environment.mockApiUrl);
  }

  bulkToggleFavorites(requestParams: IBitfApiRequest): Observable<IBitfApiResponse<any>> {
    return this.request({
      method: 'POST',
      action: 'bulk',
      modelMapper: 'action',
      ...requestParams,
    });
  }

  getFilters(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<Filter>> {
    return this.request({
      method: 'POST',
      relation: 'filters',
      modelMapper: 'filters',
      ...requestParams,
    });
  }

  getAutocomplete(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<Tag[]>> {
    return this.request({
      method: 'POST',
      relation: 'autocomplete',
      modelMapper: 'tags',
      ...requestParams,
    });
  }
}
