import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';
import { EBitfApiRequestPartsNames, EApiRequestPartKeys, EBitfPQueryComparators } from '@enums';
import { IBitfPartData } from '@interfaces';
import { ESampleKey, ESampleScale, ESampleType } from '@common/enums/common.enum';
import { CONSTANTS } from '@web/constants';

export class FilterApiRequestPart extends BitfApiRequestPart {
  constructor({
    key,
    partName,
    defaultPartData,
  }: {
    key?: EApiRequestPartKeys;
    partName?: EBitfApiRequestPartsNames;
    defaultPartData?: IBitfPartData;
  } = {}) {
    if (!key) {
      key = EApiRequestPartKeys.FILTERS;
    }
    if (!partName) {
      partName = EBitfApiRequestPartsNames.QUERY;
    }
    super(key, partName, defaultPartData);
  }

  get isFilterActive() {
    const { prompt, promptHistory, sampleType, minBpm, maxBpm, key, scale, tags, excludedTags } = this.data;
    return (
      prompt !== '' ||
      promptHistory !== '' ||
      sampleType !== ESampleType.ALL ||
      minBpm !== CONSTANTS.BPM_MIN_DEFAULT_VALUE ||
      maxBpm !== CONSTANTS.BPM_MAX_DEFAULT_VALUE ||
      key !== ESampleKey.ALL ||
      scale !== ESampleScale.ALL ||
      tags.length ||
      excludedTags.length
    );
  }

  build(): void {
    if (this.data) {
      this.part = [];
      const {
        prompt,
        promptHistory,
        sampleType,
        isRangeBpm,
        minBpm,
        maxBpm,
        bpm,
        key,
        scale,
        tags,
        excludedTags,
      } = this.data;

      // PROMPT
      if (prompt) {
        this.part.push({
          name: 'prompt',
          value: prompt,
          comparator: EBitfPQueryComparators.CONTAINS,
          operator: null,
        });
      } else {
        this.part.push({
          name: 'prompt',
          value: '',
          comparator: EBitfPQueryComparators.CONTAINS,
          operator: null,
        });
      }

      // PROMPT HISTORY
      if (promptHistory) {
        this.part.push({
          name: 'promptHistory',
          value: promptHistory,
          comparator: EBitfPQueryComparators.CONTAINS,
          operator: null,
        });
      } else {
        this.part.push({
          name: 'promptHistory',
          value: '',
          comparator: EBitfPQueryComparators.CONTAINS,
          operator: null,
        });
      }

      // SAMPLE TYPE
      if (sampleType) {
        this.part.push({
          name: 'sampleType',
          value: sampleType,
          comparator: EBitfPQueryComparators.EQUAL,
          operator: null,
        });
      } else {
        this.part.push({
          name: 'sampleType',
          value: ESampleType.ALL,
          comparator: EBitfPQueryComparators.EQUAL,
          operator: null,
        });
      }

      // RANGE
      if (isRangeBpm || isRangeBpm === undefined) {
        const min = Math.min(
          Math.max(minBpm, CONSTANTS.BPM_MIN_DEFAULT_VALUE),
          CONSTANTS.BPM_MAX_DEFAULT_VALUE
        );
        let max = Math.min(
          Math.max(maxBpm, CONSTANTS.BPM_MIN_DEFAULT_VALUE),
          CONSTANTS.BPM_MAX_DEFAULT_VALUE
        );
        max = Math.max(min, max);
        this.part.push({
          name: 'bpm',
          value: min,
          comparator: EBitfPQueryComparators.GREATEREQUAL,
          operator: null,
        });
        this.part.push({
          name: 'bpm',
          value: max,
          comparator: EBitfPQueryComparators.LOWEREQUAL,
          operator: null,
        });
      } else {
        this.part.push({
          name: 'bpm',
          value: Math.min(Math.max(bpm, CONSTANTS.BPM_MIN_DEFAULT_VALUE), CONSTANTS.BPM_MAX_DEFAULT_VALUE),
          comparator: EBitfPQueryComparators.EQUAL,
          operator: null,
        });
      }

      // KEY
      if (key) {
        this.part.push({
          name: 'key',
          value: key,
          comparator: EBitfPQueryComparators.EQUAL,
          operator: null,
        });
      } else {
        this.part.push({
          name: 'key',
          value: ESampleKey.ALL,
          comparator: EBitfPQueryComparators.EQUAL,
          operator: null,
        });
      }

      // SCALE
      if (scale) {
        this.part.push({
          name: 'scale',
          value: scale,
          comparator: EBitfPQueryComparators.EQUAL,
          operator: null,
        });
      } else {
        this.part.push({
          name: 'scale',
          value: ESampleScale.ALL,
          comparator: EBitfPQueryComparators.EQUAL,
          operator: null,
        });
      }

      // TAGS
      this.part.push({
        name: 'tags',
        value: tags || [],
        comparator: EBitfPQueryComparators.IN,
        operator: null,
      });
      this.part.push({
        name: 'tags',
        value: excludedTags || [],
        comparator: EBitfPQueryComparators.NOTIN,
        operator: null,
      });
    } else {
      this.part = undefined;
    }
  }
}
