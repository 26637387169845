<ng-container *ngIf="sample">
  <ng-container *ngIf="config.shouldShowCoverAndTitle">
    <div class="row flex-nowrap mt-3 no-gutters trick-min-width-0">
      <div class="col-auto pr-2">
        <ss-sample-cover [sample]="sample"
          [imageWidth]="56"
          [size]="'56px'"></ss-sample-cover>
      </div>
      <div class="col px-2 trick-min-width-0">
        <ss-sample-title-and-tags [sample]="sample"
          [truncateLabel]="false"
          [numberOfVisibleTags]="5"></ss-sample-title-and-tags>
      </div>
    </div>
    <mat-divider class="mt-3"></mat-divider>
  </ng-container>

  <div class="row">
    <div class="col">
      <mat-list>
        <mat-list-item *ngIf="config.shouldShowDownload">
          <ss-sample-download-button class="d-block w-100"
            [sample]="sample"
            [showLabel]="true"
            (beforeAction)="onCloseBottomSheet()"></ss-sample-download-button>
        </mat-list-item>

        <mat-list-item *ngIf="config.shouldShowFavorite">
          <ss-sample-favorite-button class="d-block w-100"
            [sample]="sample"
            [showLabel]="true"
            (beforeAction)="onCloseBottomSheet()"></ss-sample-favorite-button>
        </mat-list-item>

        <ng-container *ngIf="sample?.generatedBy === eSampleGeneratedBy.AI">
          <mat-divider class="my-2"></mat-divider>
          <mat-list-item>
            <ss-sample-ai-view-prompt-button class="d-block w-100"
              [sample]="sample"
              [IsInMatMenu]="false"
              [showLabel]="true"></ss-sample-ai-view-prompt-button>
          </mat-list-item>
        </ng-container>

        <mat-list-item>
          <ss-sample-generate-variation-sound-button class="d-block w-100"
            [sample]="sample"
            [IsInMatMenu]="false"
            [showLabel]="true"
            (beforeAction)="onCloseBottomSheet()"></ss-sample-generate-variation-sound-button>
        </mat-list-item>

        <ng-container *ngIf="sample?.generatedBy === eSampleGeneratedBy.AI && sample?.aiGeneration.prompt">
          <mat-list-item>
            <ss-sample-regenerate-sound-button class="d-block w-100"
              [sample]="sample"
              [IsInMatMenu]="false"
              [showLabel]="true"></ss-sample-regenerate-sound-button>
          </mat-list-item>
        </ng-container>

        <ng-container *ngIf="sample?.generatedBy === eSampleGeneratedBy.AI">
          <mat-list-item>
            <ss-sample-feedback-button class="d-block w-100"
              [viewMode]="eAiFeedback.LIKE"
              [sample]="sample"
              [showLabel]="true"
              (beforeAction)="onCloseBottomSheet()"></ss-sample-feedback-button>
          </mat-list-item>
          <mat-list-item>
            <ss-sample-feedback-button class="d-block w-100"
              [viewMode]="eAiFeedback.DISLIKE"
              [sample]="sample"
              [showLabel]="true"
              (beforeAction)="onCloseBottomSheet()"></ss-sample-feedback-button>
          </mat-list-item>
        </ng-container>

        <mat-list-item *ngIf="config.shouldShowViewPack && sample.pack">
          <button mat-button
            (click)="onViewPack()"
            class="w-100 justify-content-start">
            <mat-icon>inventory_2</mat-icon>
            View Pack
          </button>
        </mat-list-item>

        <ng-container *ngIf="config.shouldShowCollection">
          <mat-divider class="my-2"></mat-divider>
          <mat-list-item>
            <ss-sample-collection-button class="d-block w-100"
              [sample]="sample"
              [showLabel]="true"
              (beforeAction)="onCloseBottomSheet()"></ss-sample-collection-button>
          </mat-list-item>
          <mat-list-item *ngIf="collection">
            <ss-sample-collection-button class="d-block w-100"
              [sample]="sample"
              [collection]="collection"
              [showLabel]="true"
              (beforeAction)="onCloseBottomSheet()"></ss-sample-collection-button>
          </mat-list-item>
        </ng-container>
      </mat-list>
    </div>
  </div>
</ng-container>