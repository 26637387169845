import { SuperModel } from './super-model.model';

export class Genre extends SuperModel {
  label?: string;
  coverUrl?: string;
  description?: string;

  constructor(data = {}) {
    super(data);
    this.coverUrl = this.coverUrl || '/assets/images/covers/default-genre-cover.jpg';
  }

  get serialised() {
    return this.id;
  }
}
