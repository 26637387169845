<div (click)="onClick()"
  class="{{'border-' + color}} card h-100 d-flex flex-column justify-content-between"
  [ngClass]="{'is-clickable cursor-pointer': !!linkUrl, 'mouseover-effect': storeService.store.activeBreakpoints.isWeb}">
  <mat-icon *ngIf="isSelected"
    class="selected-icon scale-up"
    color="primary">check_circle</mat-icon>

  <div *ngIf="title || subTitle"
    class="title-container pb-3 d-flex flex-column justify-content-between flex-grow-1">
    <h3 *ngIf="title"
      data-testid="card-title"
      class="title text-active m-0"
      [matTooltip]="title"
      matTooltipPosition="above"
      matTooltipShowDelay="800">{{title}}</h3>
    <h4 *ngIf="subTitle"
      class="mt-1 m-0 mat-caption text-dark">{{subTitle}}</h4>
  </div>

  <div class="cover-container"
    *ngIf="imageUrl">
    <bitf-image [sources]="{'xs': {'1x': imageUrl+'?width=160','2x': imageUrl+'?width=320'}}"
      [nativeLazy]="true"
      [layout]="'fixed'"></bitf-image>

    <div class="cover-scrim"
      *ngIf="sample?.id"></div>

    <ss-audio-button *ngIf="sample?.id"
      mat-card-image
      [displayAsFab]="true"
      (click)="onPlaySampleClick($event)"
      [sample]="sample"></ss-audio-button>
  </div>

</div>