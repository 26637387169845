import { SuperModel } from './super-model.model';

export class Tag extends SuperModel {
  label?: string;
  category?: string;
  children?: Tag[];

  constructor(data = {}) {
    super(data);
    if (this.children?.length) {
      this.children = this.children.map((child: Tag) => new Tag(child));
    }
  }

  get serialised() {
    return {
      id: this.id,
    };
  }
}
