<div class="d-flex flex-column"
  *ngIf="sample">
  <div class="break-spaces break-word label text-active"
    data-testid="sample-name"
    [title]="sample?.label">{{sample?.label}}</div>
  <div class="mat-caption overflow-hidden"
    data-testid="tag-list">
    <span *ngFor="let tag of visibleTags"
      data-testid="tag"
      class="mr-1 text-dark">{{tag.label}}</span>
    <span *ngIf="invisibleTags.length"
      class="extra-tags-chip text-dark"
      [matTooltip]="invisibleTagsString">+{{invisibleTags.length}}</span>
  </div>
</div>