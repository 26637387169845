import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { Filter, SampleDownload, Tag } from '@common/core/models';
import { IBitfApiRequest, IBitfApiResponse } from '@common/interfaces';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DownloadsService extends BitfApiService {
  constructor(public injector: Injector) {
    super('downloads', injector);
    // super('downloads', injector, environment.mockApiUrl);
  }

  bulkDownloadSamples(requestParams: IBitfApiRequest): Observable<IBitfApiResponse<SampleDownload>> {
    return this.request({
      method: 'POST',
      relation: 'bulk',
      modelMapper: 'download',
      ...requestParams,
    });
  }

  getFilters(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<Filter>> {
    return this.request({
      method: 'POST',
      relation: 'filters',
      modelMapper: 'filters',
      ...requestParams,
    });
  }

  getAutocomplete(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<Tag[]>> {
    return this.request({
      method: 'POST',
      relation: 'autocomplete',
      modelMapper: 'tags',
      ...requestParams,
    });
  }
}
