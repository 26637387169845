import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import ReconnectingWebSocket, { Options } from './utils/reconnecting-websocket';
import { IBitfSocketsService } from '../bitf-sockets.interface';
import { AuthService } from '@common/services';
import { environment } from '@env/environment';
import { IMessageToServer } from './bitf-websockets.intertface';

@Injectable({
  providedIn: 'root',
})
export class BitfWebsocketService<T> implements IBitfSocketsService<T> {
  constructor(private authService: AuthService) {}

  private ws!: ReconnectingWebSocket;
  messages$: Subject<T> = new Subject<T>();

  createObservableSocket(): Subject<T> {
    if (this.ws) {
      return this.messages$;
    }

    const options: Options = { debug: false, maxRetries: 1000 };
    this.ws = new ReconnectingWebSocket(
      () => `${environment.sockets.url}?Auth=${this.authService?.authTokenMetaData?.token}`,
      [],
      options
    );

    this.ws.onmessage = event => this.messages$.next(JSON.parse(event.data) as T);

    return this.messages$;
  }

  send(message: IMessageToServer) {
    this.ws.send(JSON.stringify(message));
  }
}
