import { Injectable, Injector } from '@angular/core';
import { AiSettings, HomeSettings } from '@common/core/models';
import { BitfApiService } from '@common/libs/bitforce/core/services/api/bitf-api.service';
import { IBitfApiRequest, IBitfApiResponse } from '@web/interfaces';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService extends BitfApiService {
  constructor(public injector: Injector) {
    super('app-settings', injector);
    // super('app-settings', injector, environment.mockApiUrl);
  }

  getHome(request: IBitfApiRequest = {}): Observable<IBitfApiResponse<HomeSettings>> {
    return this.request({ method: 'POST', action: 'home', modelMapper: 'home-settings', ...request });
  }
  getAi(request: IBitfApiRequest = {}): Observable<IBitfApiResponse<AiSettings>> {
    return this.request({ method: 'POST', action: 'ai', modelMapper: 'ai-settings', ...request });
  }
}
