<div class="row flex-wrap mt-3 mb-1 align-items-center"
  *ngIf="shouldShowTagsBar">

  <div class="pr-md-0 pb-1 col-auto d-flex align-items-center"
    *ngIf="filters.promptHistory">
    <span class="pr-1">Search results for:&nbsp;</span>
    <ss-tag class="max-w-75"
      data-testid="prompt-history-tag"
      [label]="filters.promptHistory"
      [showDeleteButton]="true"
      (tagRemove)="onRemovePrompt()"></ss-tag>
  </div>

  <div class="pr-0 pb-1 d-flex col-auto align-items-center flex-shrink-1"
    *ngIf="shouldShowTags">
    <span class="pr-1">Tags:</span>
    <ss-tag class="mr-1"
      data-testid="tag-list"
      *ngFor="let tag of filters?.tags"
      [tag]="tag"
      [filters]="filters"
      [disableHighlight]="true"
      (change)="onTagChange()"></ss-tag>
    <ss-tag class="mr-1"
      data-testid="excluded-tag-list"
      *ngFor="let tag of filters?.excludedTags"
      [tag]="tag"
      [filters]="filters"
      [disableHighlight]="true"
      (change)="onTagChange()"></ss-tag>
  </div>

  <div class="pr-0 pb-1 d-flex col-auto align-items-center flex-shrink-1"
    data-testid="details-tags"
    *ngIf="shouldShowDetails">
    <span class="pr-1">Details:</span>
    <!-- BPM -->
    <ss-tag class="mr-1"
      *ngIf="filters.minBpm && !(filters.minBpm === constants.BPM_MIN_DEFAULT_VALUE && filters.maxBpm === constants.BPM_MAX_DEFAULT_VALUE)"
      [label]="filters.minBpm + (filters.maxBpm? (filters.minBpm !== filters.maxBpm ? '-'+ filters.maxBpm : '') :'') + ' BPM'"
      [showDeleteButton]="true"
      (tagRemove)="onRemoveBpm()"></ss-tag>

    <!-- Sample type -->
    <ss-tag class="mr-1"
      *ngIf="filters.sampleType && filters.sampleType !== eSampleType.ALL"
      label="{{filters.sampleType | titlecase}}"
      [showDeleteButton]="true"
      (tagRemove)="onRemoveSampleType()"></ss-tag>

    <!-- Key -->
    <ss-tag class="mr-1"
      *ngIf="filters.key && filters.key !== eSampleKey.ALL"
      label="{{filters.key}}"
      [showDeleteButton]="true"
      (tagRemove)="onRemoveKey()"></ss-tag>

    <!-- Scale -->
    <ss-tag class="mr-1"
      *ngIf="filters.scale && filters.scale !== eSampleScale.ALL"
      label="{{filters.scale | titlecase}}"
      [showDeleteButton]="true"
      (tagRemove)="onRemoveScale()"></ss-tag>
  </div>

  <div class="d-none d-sm-block col-auto pb-1 ml-auto">
    <button mat-button
      data-testid="reset-filters-button"
      (click)="onResetFilters()">Reset filters</button>
  </div>

</div>