import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Filter } from '@common/core/models';
import { CONSTANTS } from '@web/constants';

@Component({
  selector: 'ss-search-samples-bpm',
  templateUrl: './search-samples-bpm.component.html',
  styleUrls: ['./search-samples-bpm.component.scss'],
})
export class SearchSamplesBpmComponent {
  @Input() filters: Filter;
  @Output() change = new EventEmitter();
  constants = CONSTANTS;
  private debounceHandler;

  onResetBpm() {
    if (this.filters.isRangeBpm) {
      this.filters.minBpm = CONSTANTS.BPM_MIN_DEFAULT_VALUE;
      this.filters.maxBpm = CONSTANTS.BPM_MIN_DEFAULT_VALUE;
    } else {
      this.filters.bpm = CONSTANTS.BPM_DEFAULT_VALUE;
    }
    this.onBpmChange();
  }

  onInputChange() {
    if (this.debounceHandler) {
      clearTimeout(this.debounceHandler);
    }
    this.debounceHandler = setTimeout(() => {
      this.onBpmChange();
    }, 800);
  }

  onBpmChange() {
    this.change.emit();
  }
}
