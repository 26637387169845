import { ESampleKey, ESampleScale, ESampleType } from '@common/enums/common.enum';
import { SuperModel } from './super-model.model';
import { Tag } from './tag.model';

export class Filter extends SuperModel {
  prompt?: string;
  promptHistory?: string;
  sampleType?: ESampleType;
  minBpm?: number;
  maxBpm?: number;
  bpm?: number;
  isRangeBpm = true;
  key?: ESampleKey;
  scale?: ESampleScale;
  tags?: Tag[];
  excludedTags?: Tag[];
  genre?: Tag[];
  instruments?: Tag[];
  labels?: Tag[];
  moods?: Tag[];

  constructor(data = {}) {
    super(data);
    if (this.tags?.length) {
      this.tags = this.tags.map((tag: Tag) => new Tag(tag));
    }
    if (this.excludedTags?.length) {
      this.excludedTags = this.excludedTags.map((tag: Tag) => new Tag(tag));
    }
    if (this.genre?.length) {
      this.genre = this.genre.map((tag: Tag) => new Tag(tag));
    }
    if (this.instruments?.length) {
      this.instruments = this.instruments.map((tag: Tag) => new Tag(tag));
    }
    if (this.labels?.length) {
      this.labels = this.labels.map((tag: Tag) => new Tag(tag));
    }
    if (this.moods?.length) {
      this.moods = this.moods.map((tag: Tag) => new Tag(tag));
    }
  }

  get serialised() {
    return {};
  }
}
