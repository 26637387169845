import { Component, Input, OnInit } from '@angular/core';
import { SearchSamplesSuperFilterComponent } from '../search-samples-super-filter/search-samples-super-filter.component';
import { EFilterCategory } from '@enums';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'ss-search-samples-filters',
  templateUrl: './search-samples-filters.component.html',
  styleUrls: ['./search-samples-filters.component.scss'],
})
export class SearchSamplesFiltersComponent extends SearchSamplesSuperFilterComponent implements OnInit {
  @Input() closeFn: () => void;
  ngOnInit(): void {
    super.ngOnInit();
    // NOTE this is needed in case the user opens the filters without
    // having done a search before which trigger a filter get and filterPart.data update
    this.getFilters();
  }

  eFilterCategory = EFilterCategory;
  selectedCategory: EFilterCategory = EFilterCategory.INSTRUMENTS;

  onCategoryChange(change: MatButtonToggleChange) {
    this.selectedCategory = change.value;
  }

  onClose() {
    this.closeFn();
  }
}
