<div *ngIf="filters.isRangeBpm"
  class="d-flex align-items-center justify-content-between">
  <span class="mat-caption mr-1">BPM</span>
  <div class="bpm-slider-range text-center flex-grow-1">
    <mat-slider class="w-100 custom-slider"
      [min]="constants.BPM_MIN_DEFAULT_VALUE"
      [max]="constants.BPM_MAX_DEFAULT_VALUE">
      <input matSliderStartThumb
        [(ngModel)]="filters.minBpm"
        (dragEnd)="onBpmChange()">
      <input matSliderEndThumb
        [(ngModel)]="filters.maxBpm"
        (dragEnd)="onBpmChange()">
    </mat-slider>
  </div>

  <div class="mat-caption bpm-range-value">
    {{filters.minBpm}}<span *ngIf="filters.minBpm !== filters.maxBpm"> - {{filters.maxBpm}}</span>
  </div>
</div>

<div *ngIf="!filters.isRangeBpm"
  class="d-flex align-items-center justify-content-between">
  <span class="mat-caption mr-1">BPM</span>
  <div class="flex-grow-1 text-center">
    <mat-slider class="w-100 custom-slider"
      [min]="constants.BPM_MIN_DEFAULT_VALUE"
      [max]="constants.BPM_MAX_DEFAULT_VALUE"
      [step]="1"
      [showTickMarks]="true"
      (dragEnd)="onBpmChange()">
      <input matSliderThumb
        [(ngModel)]="filters.bpm">
    </mat-slider>
  </div>
  <span class="bpm-single-value mat-caption  d-inline-block">
    {{filters.bpm || constants.BPM_MIN_DEFAULT_VALUE}}
  </span>
  <!-- <mat-form-field class="form-field-condensed">
      <mat-label>Bpm</mat-label>
      <input matInput
        type="number"
        [min]="40"
        [max]="240"
        [(ngModel)]="filters.bpm"
        (input)="onInputChange()">
    </mat-form-field> -->
</div>

<!-- <div class="col-auto">
    <button mat-icon-button
      (click)="onResetBpm()"><mat-icon>restart_alt</mat-icon></button>
  </div> -->
<!-- <mat-checkbox [(ngModel)]="filters.isRangeBpm">Range</mat-checkbox> -->