import { Genre } from './genre.model';
import { SuperModel } from './super-model.model';

export class UserSettings extends SuperModel {
  favoriteGenres: Genre[];

  constructor(data = {}) {
    super(data);
    if (this.favoriteGenres?.length) {
      this.favoriteGenres = this.favoriteGenres.map((tag: Genre) => new Genre(tag));
    }
  }

  get serialised() {
    return {
      favoriteGenres: this.favoriteGenres.map((tag: Genre) => tag.serialised),
    };
  }
}
