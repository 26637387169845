import { Component, Input } from '@angular/core';

@Component({
  selector: 'ss-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss'],
})
export class NotificationBarComponent {
  @Input() message: string;
  @Input() type: 'INFO' | 'ERROR' | 'PROMO' = 'INFO';
}
