import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Sample } from '@common/core/models';
import { StoreService } from '@common/core/services';

@Component({
  selector: 'ss-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss'],
})
export class ItemCardComponent {
  @Input() linkUrl?: string;
  @Input() imageUrl?: string;
  @Input() color?: string;
  @Input() title?: string;
  @Input() subTitle?: string;
  @Input() isSelected = false;
  @Input() sample: Sample;

  constructor(private router: Router, public storeService: StoreService) {}

  onClick() {
    if (this.linkUrl) {
      this.router.navigateByUrl(this.linkUrl);
    }
  }

  onPlaySampleClick($event) {
    $event.stopPropagation();
    $event.preventDefault();
  }
}
