import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Filter } from '@common/core/models';
import { ESampleType } from '@common/enums/common.enum';

@Component({
  selector: 'ss-search-samples-type',
  templateUrl: './search-samples-type.component.html',
  styleUrls: ['./search-samples-type.component.scss'],
})
export class SearchSamplesTypeComponent {
  @Input() filters: Filter;
  @Output() change = new EventEmitter();

  eSampleType = ESampleType;

  onChange(event: MatButtonToggleChange) {
    this.filters.sampleType = event.value;
    this.change.emit();
  }
}
