<!-- These four buttons are used only in the mobile action bar, not in desktop
because the collection buttons are inside the menu button ... -->
<ng-container *ngIf="!IsInMatMenu">

  <ng-container *ngIf="!isBulk">
    <ng-container *ngIf="!collection">
      <button mat-button
        [matTooltip]="!showLabel ? 'Add to collection':''"
        matTooltipPosition="above"
        matTooltipShowDelay="500"
        (click)="onAddToCollection()">
        <mat-icon>playlist_add</mat-icon>
        <ng-container *ngIf="showLabel">Add to collection</ng-container>
      </button>
    </ng-container>

    <ng-container *ngIf="collection">
      <button mat-button
        [matTooltip]="!showLabel ? 'Remove from collection':''"
        matTooltipPosition="above"
        matTooltipShowDelay="500"
        (click)="onRemoveFromCollection()">
        <mat-icon>playlist_remove</mat-icon>
        <ng-container *ngIf="showLabel">Remove from collection</ng-container>
      </button>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isBulk">
    <button mat-button
      *ngIf="!collection"
      (click)="onAddToCollection()"
      class="bulk-action-button">
      <mat-icon>playlist_add</mat-icon>
      Add to collection
    </button>

    <button mat-button
      *ngIf="collection"
      [matMenuTriggerFor]="menu"
      class="bulk-action-button">
      <mat-icon>library_music</mat-icon>
      Manage collection
    </button>
  </ng-container>
</ng-container>

<ng-container *ngIf="IsInMatMenu">
  <ng-container *ngTemplateOutlet="matMenuItems"></ng-container>
</ng-container>

<mat-menu #menu="matMenu">
  <ng-container *ngTemplateOutlet="matMenuItems"></ng-container>
</mat-menu>

<ng-template #matMenuItems>
  <button mat-menu-item
    (click)="onAddToCollection()">
    <mat-icon>playlist_add</mat-icon>Add to collection</button>

  <button mat-menu-item
    color="warn"
    *ngIf="collection"
    (click)="onRemoveFromCollection()">
    <mat-icon>playlist_remove</mat-icon>Remove from collection</button>
</ng-template>