import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService extends BitfApiService {
  constructor(public injector: Injector) {
    super('auth', injector);
  }

  getToken(requestParams) {
    return this.post({
      path: '/login',
      ...requestParams,
    });
  }

  getShopifySSOToken(requestParams) {
    return this.post({
      path: '/shopify-login',
      ...requestParams,
    });
  }

  logout(refreshToken: string) {
    return this.post({
      path: '/logout',
      body: {
        refreshToken,
      },
    });
  }

  refreshToken(refreshToken: string) {
    return this.post({
      path: '/refresh',
      body: {
        refreshToken,
      },
    });
  }
}
