import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { IBitfApiRequest, IBitfApiResponse } from '@common/interfaces';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService extends BitfApiService {
  constructor(public injector: Injector) {
    super('feedback', injector);
    //super('feedback', injector, environment.mockApiUrl);
  }

  bulkChangeFeedback(requestParams: IBitfApiRequest): Observable<IBitfApiResponse<any>> {
    return this.request({
      method: 'POST',
      action: 'bulk',
      modelMapper: 'action',
      ...requestParams,
    });
  }
}
