<ss-search-samples-table *ngIf="shouldShowTable"
  [apiCallStateName]="apiCallStateName"
  [samples]="samples"
  [collection]="collection"
  [pagination]="pagination"
  [shouldShowAddToFavorite]="shouldShowAddToFavorite"
  [shouldShowViewPack]="shouldShowViewPack"
  [shouldShowAiFeedback]="shouldShowAiFeedback"
  [shouldShowRefreshListButton]="shouldShowRefreshListButton"
  [isLoading]="isLoading"
  [showSorting]="showSorting"
  [isLoadingMessage]="isLoadingMessage"
  (refreshList)="refreshList.emit()"></ss-search-samples-table>

<ss-search-samples-not-found *ngIf="shouldShowNotFound"></ss-search-samples-not-found>

<ss-inline-loader *ngIf="shouldShowInlineLoader">
</ss-inline-loader>

<ng-content select="[welcome-page]"
  *ngIf="shouldShowWelcomePage"></ng-content>