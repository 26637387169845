<ng-container *ngIf="IsInMatMenu">
  <div class="ai-icon">
    <img src="assets/icons/a.svg"
      alt="A" />
    <img src="assets/icons/i.svg"
      alt="I" />
  </div>
  <button mat-menu-item
    (click)="onAiGenerate()">
    <mat-icon>graphic_eq</mat-icon>
    Regenerate Sound
  </button>

</ng-container>

<ng-container *ngIf="!IsInMatMenu">
  <div class="ai-icon">
    <img src="assets/icons/a.svg"
      alt="A" />
    <img src="assets/icons/i.svg"
      alt="I" />
  </div>
  <button mat-button
    [matTooltip]="!showLabel ? 'Regenerate Sound' : ''"
    [matTooltipShowDelay]="!showLabel ? '1000' : ''"
    class="w-100 justify-content-start"
    (click)="onAiGenerate()">
    <mat-icon>graphic_eq</mat-icon>
    <span *ngIf="showLabel">Regenerate Sound</span></button>
</ng-container>