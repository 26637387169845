import { Component } from '@angular/core';
import { Pack, UserSuggestedPack } from '@common/core/models';
import { StoreService, UsersService } from '@web/core/services';

@Component({
  selector: 'ss-user-suggested-packs',
  templateUrl: './user-suggested-packs.component.html',
  styleUrls: ['./user-suggested-packs.component.scss'],
})
export class UserSuggestedPacksComponent {
  suggestedPacks: UserSuggestedPack[] = [];
  isLoading = false;

  constructor(private usersService: UsersService, public storeService: StoreService) {
    this.isLoading = true;
    this.usersService.getSuggestedPacks({ id: this.storeService.store.user.id }).subscribe(response => {
      this.suggestedPacks = response.content;
      this.isLoading = false;
    });
  }

  trackByFn(_, pack: Pack) {
    return pack.id;
  }
}
