import { BitfPaginationApiRequestPart } from '@bitf/core/api-call-state/bitf-pagination-api-request-part.builder';

import { EApiCallStateNames, EBitfApiSortDirection } from '@enums';
import { IBitfApiCallState } from '@interfaces';
import { DefaultApiCallStateMapper } from './default-api-call-state.mapper';
import {
  BitfPSearchApiRequestPart,
  BitfPSortApiRequestPart,
} from '@common/libs/bitforce/core/api-call-state/bitf-p-api-call-state';
import { FilterApiRequestPart } from './filter-api-request-part.builder';
import { ESampleCustomSort, ESampleKey, ESampleScale, ESampleType } from '@common/enums/common.enum';
import { CONSTANTS } from '@web/constants';

const defaultFilters = {
  prompt: '',
  promptHistory: '',
  sampleType: ESampleType.ALL,
  isRangeBpm: true,
  minBpm: CONSTANTS.BPM_MIN_DEFAULT_VALUE,
  maxBpm: CONSTANTS.BPM_MAX_DEFAULT_VALUE,
  bpm: CONSTANTS.BPM_DEFAULT_VALUE,
  key: ESampleKey.ALL,
  scale: ESampleScale.ALL,
  tags: [],
  excludedTags: [],
};

export const apiCallStateConfig = [
  {
    apiCallStateName: EApiCallStateNames.SAMPLES,
    apiRequest: {},
    requestParts: [
      new BitfPaginationApiRequestPart({
        initialPagination: { page: 1, size: 20 },
      }),
      new BitfPSearchApiRequestPart({}),
      new BitfPSortApiRequestPart({
        defaultPartData: {
          data: {
            property: ESampleCustomSort.POPULAR,
            direction: EBitfApiSortDirection.DESC,
          },
        },
      }),
      new FilterApiRequestPart({
        defaultPartData: {
          data: defaultFilters,
        },
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.FAVORITES,
    apiRequest: {},
    requestParts: [
      new BitfPaginationApiRequestPart({
        initialPagination: { page: 1, size: 20 },
      }),
      new BitfPSearchApiRequestPart({}),
      new BitfPSortApiRequestPart({
        defaultPartData: {
          data: {
            property: ESampleCustomSort.RECENT,
            direction: EBitfApiSortDirection.DESC,
          },
        },
      }),
      new FilterApiRequestPart({
        defaultPartData: {
          data: defaultFilters,
        },
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.DOWNLOADS,
    apiRequest: {},
    requestParts: [
      new BitfPaginationApiRequestPart({
        initialPagination: { page: 1, size: 20 },
      }),
      new BitfPSearchApiRequestPart({}),
      new BitfPSortApiRequestPart({
        defaultPartData: {
          data: {
            property: ESampleCustomSort.RECENT,
            direction: EBitfApiSortDirection.DESC,
          },
        },
      }),
      new FilterApiRequestPart({
        defaultPartData: {
          data: defaultFilters,
        },
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.COLLECTION_SAMPLES,
    apiRequest: {},
    requestParts: [
      new BitfPaginationApiRequestPart({
        initialPagination: { page: 1, size: 20 },
      }),
      new BitfPSearchApiRequestPart({}),
      new BitfPSortApiRequestPart({
        defaultPartData: {
          data: {
            property: ESampleCustomSort.RECENT,
            direction: EBitfApiSortDirection.DESC,
          },
        },
      }),
      new FilterApiRequestPart({
        defaultPartData: {
          data: defaultFilters,
        },
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.PACK_SAMPLES,
    apiRequest: {},
    requestParts: [
      new BitfPaginationApiRequestPart({
        initialPagination: { page: 1, size: 20 },
      }),
      new BitfPSearchApiRequestPart({}),
      new BitfPSortApiRequestPart({
        defaultPartData: {
          data: {
            property: ESampleCustomSort.POPULAR,
            direction: EBitfApiSortDirection.DESC,
          },
        },
      }),
      new FilterApiRequestPart({
        defaultPartData: {
          data: defaultFilters,
        },
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.AI,
    apiRequest: {},
    requestParts: [
      new BitfPaginationApiRequestPart({
        initialPagination: { page: 1, size: 20 },
      }),
      new BitfPSortApiRequestPart({
        defaultPartData: {
          data: {
            property: ESampleCustomSort.RECENT,
            direction: EBitfApiSortDirection.DESC,
          },
        },
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
];
