<div class="d-flex">
  <ng-container *ngIf="this.sample && !this.isBulk && viewMode !== this.eAiFeedback.DISLIKE">
    <button mat-icon-button
      *ngIf="!showLabel"
      matTooltip="Like"
      matTooltipPosition="above"
      matTooltipShowDelay="1000"
      (click)="onLikeFeedbackSample($event,this.sample)">
      <mat-icon class="text-active"
        [ngClass]="{'material-icons-outlined': this.sample.aiGeneration.aiFeedback !== this.eAiFeedback.LIKE}">thumb_up</mat-icon>
    </button>
    <button mat-button
      *ngIf="showLabel"
      (click)="onLikeFeedbackSample($event,this.sample)">
      <mat-icon class="text-active"
        [ngClass]="{'material-icons-outlined': this.sample.aiGeneration.aiFeedback !== this.eAiFeedback.LIKE}">thumb_up</mat-icon>
      {{this.sample.aiGeneration.aiFeedback !== this.eAiFeedback.LIKE ? 'Like' : 'Remove Like'}}
    </button>
  </ng-container>

  <ng-container *ngIf="this.sample && !this.isBulk && viewMode !== this.eAiFeedback.LIKE">
    <button mat-icon-button
      *ngIf="!showLabel"
      matTooltip="Dislike"
      matTooltipPosition="above"
      matTooltipShowDelay="1000"
      (click)="onDislikeFeedbackSample($event,this.sample)">
      <mat-icon class="text-active"
        [ngClass]="{'material-icons-outlined': this.sample.aiGeneration.aiFeedback !== this.eAiFeedback.DISLIKE}">thumb_down</mat-icon>
    </button>
    <button mat-button
      *ngIf="showLabel"
      (click)="onDislikeFeedbackSample($event,this.sample)">
      <mat-icon class="text-active"
        [ngClass]="{'material-icons-outlined': this.sample.aiGeneration.aiFeedback !== this.eAiFeedback.DISLIKE}">thumb_down</mat-icon>
      {{this.sample.aiGeneration.aiFeedback !== this.eAiFeedback.DISLIKE ? 'Dislike' : 'Remove Dislike'}}
    </button>
  </ng-container>
</div>


<!-- bulk buttons -->
<button mat-button
  *ngIf="this.isBulk && viewMode !== this.eAiFeedback.DISLIKE"
  (click)="onLikeFeedbackBulk($event)">
  <mat-icon>thumb_up</mat-icon>
  Like
</button>


<button mat-button
  *ngIf="this.isBulk  && viewMode !== this.eAiFeedback.LIKE"
  (click)="onDislikeFeedbackBulk($event)">
  <mat-icon>thumb_down</mat-icon>
  Dislike
</button>