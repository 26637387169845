import {
  Component,
  ElementRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Sample } from '@common/core/models';
import { StoreService } from '@common/core/services';
import { Subject } from 'rxjs';
import WaveSurfer, { WaveSurferOptions } from 'wavesurfer.js';
import { ILoadNewSampleOptions } from './super-audio-player.interface';

@Component({
  selector: 'ss-super-audio-player',
  templateUrl: './super-audio-player.component.html',
  styleUrls: ['./super-audio-player.component.scss'],
})
export abstract class SuperAudioPlayerComponent implements OnChanges, OnDestroy {
  @ViewChild('waveform') waveform: ElementRef<any>;
  @Input() sample: Sample;

  storeService: StoreService;
  waveSurfer: WaveSurfer;
  waveSurferOptions: WaveSurferOptions = {
    container: undefined,
    height: 29,
    waveColor: '#FFFFFF',
    barGap: 2,
    barWidth: 2,
    barRadius: 10,
    // barHeight: 1,
  };
  useAudioElement = false;
  audioElement: HTMLAudioElement;
  audioContext: AudioContext;
  mediaNode: MediaElementAudioSourceNode;
  filters: BiquadFilterNode[];
  equalizer: AudioNode;

  audioReady$ = new Subject();
  isLoading = false;
  duration: number;

  get durationAsMilliseconds(): number {
    return (this.duration ?? 0) * 1000;
  }

  timestamp = 0;

  constructor(protected injector: Injector) {
    this.storeService = this.injector.get(StoreService);
    const isChrome = /Chrome/i.test(navigator.userAgent);
    const isFirefox = /Firefox/i.test(navigator.userAgent);
    const isAndroid = /Android/i.test(navigator.userAgent);
    this.useAudioElement = (isChrome || isFirefox) && !isAndroid;
  }
  abstract loadNewSample(): void;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sample && changes.sample.currentValue !== changes.sample.previousValue) {
      // NOTE we've to wait for waveform to be rendered
      if (this.waveform) {
        this.loadNewSample();
      } else {
        setTimeout(() => {
          this.loadNewSample();
        }, 0);
      }
    }
  }

  onWaveSurferReady({ duration }: ILoadNewSampleOptions) {
    this.duration = duration;
    this.isLoading = false;
    this.audioReady$.next();
  }

  preventDefault($event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  protected createWaveSurfer() {
    if (!this.sample?.sampleFileUrl) {
      return;
    }
    this.isLoading = true;
    this.timestamp = Date.now();

    if (this.useAudioElement) {
      this.createAudioElement();
      this.waveSurfer = WaveSurfer.create({ ...this.waveSurferOptions, media: this.audioElement });
    } else {
      this.waveSurfer = WaveSurfer.create(this.waveSurferOptions);
    }
  }

  protected createAudioElement() {
    this.audioElement = new Audio();
    this.audioElement.controls = true;
    this.audioContext = new AudioContext();
    this.mediaNode = this.audioContext.createMediaElementSource(this.audioElement);

    // Define the equalizer bands
    // const eqBands = [32, 64, 125, 250];
    // const eqBands = [16000];
    const eqBands = [32, 64, 125, 250, 500, 1000, 2000, 4000, 8000, 16000];
    // Create a biquad filter for each band
    this.filters = eqBands.map(band => {
      const filter = this.audioContext.createBiquadFilter();
      filter.type = band <= 32 ? 'lowshelf' : band >= 16000 ? 'highshelf' : 'peaking';
      filter.gain.value = 0; // 40
      filter.Q.value = 1; // resonance
      filter.frequency.value = band; // the cut-off frequency
      return filter;
    });

    // Connect the filters and media node sequentially
    this.equalizer = this.filters.reduce((prev: AudioNode, curr) => {
      prev.connect(curr);
      return curr;
    }, this.mediaNode);

    // Connect the filters to the audio output
    this.equalizer.connect(this.audioContext.destination);
  }

  protected disposeWaveSurfer() {
    this.duration = undefined;
    this.isLoading = false;
    if (this.waveSurfer) {
      this.waveSurfer.destroy();
      this.waveSurfer = undefined;
    }
    if (this.useAudioElement) {
      this.disposeAudioElement();
    }
  }

  protected disposeAudioElement() {
    try {
      this.filters.forEach(filter => {
        filter.disconnect();
      });
      this.equalizer.disconnect(this.audioContext.destination);
      this.mediaNode.disconnect();
      this.audioContext.close();
      this.audioElement.src = '';
      this.audioElement = undefined;
      this.audioContext = undefined;
      this.mediaNode = undefined;
      this.filters = [];
      this.equalizer = undefined;
    } catch (e) {}
  }

  protected parseSampleUrl() {
    return this.sample?.sampleFileUrl.includes('http')
      ? this.sample?.sampleFileUrl
      : `/assets/samples/${this.sample?.sampleFileUrl}`;
  }

  ngOnDestroy(): void {
    this.disposeWaveSurfer();
  }
}
