import { Component } from '@angular/core';
import { Sample, Store } from '@common/core/models';
import { AppSessionService, StoreService } from '@web/core/services';
import { EWebStoreActions, eStoreActions } from '@web/enums';
import { AudioPlayerComponent } from '../audio-player/audio-player.component';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'ss-fixed-player',
  templateUrl: './fixed-player.component.html',
  styleUrls: ['./fixed-player.component.scss'],
})
export class FixedPlayerComponent {
  isPlayerExpanded = false;

  get currentSample(): Sample {
    return this.storeService.store.currentSample;
  }
  get samplesList(): Sample[] {
    return this.storeService.store.samplesList;
  }
  get audioPlayer(): AudioPlayerComponent {
    return this.storeService.store?.audioPlayer;
  }
  constructor(public storeService: StoreService, public appSessionService: AppSessionService) {
    this.storeService.selectStore([eStoreActions.BREAKPOINT]).subscribe(event => {
      if (!event.store.activeBreakpoints.isHandsetLayout) {
        this.isPlayerExpanded = false;
      }
    });
  }

  // NOTE: this is managed here in case we want to play other lists that is different from the store
  // sampleList
  onPreviousSample() {
    const currentSampleIndex = this.getCurrentSampleIndex();

    if (currentSampleIndex === -1) {
      // NOTE if the sample is not found we can set the first one in the current list if there is something
      this.setFirstSampleListItemAndPlay();
    }

    // NOTE if the sample is found and is not the first one we can set the previous one
    if (currentSampleIndex > 0) {
      this.setCurrentSampleAndPlay(this.samplesList[currentSampleIndex - 1]);
    }
  }

  onNextSample() {
    const currentSampleIndex = this.getCurrentSampleIndex();

    if (currentSampleIndex === -1) {
      // NOTE if the sample is not found we can set the first one in the current list if there is something
      this.setFirstSampleListItemAndPlay();
    }

    // NOTE if the sample is found and is not the last one we can set the next one
    if (currentSampleIndex < this.samplesList.length - 1) {
      this.setCurrentSampleAndPlay(this.samplesList[currentSampleIndex + 1]);
    }
  }

  private getCurrentSampleIndex() {
    const currentSampleIndex = this.samplesList.findIndex(sample => sample.id === this.currentSample.id);
    return currentSampleIndex;
  }

  private setFirstSampleListItemAndPlay() {
    if (this.samplesList.length) {
      this.setCurrentSampleAndPlay(this.samplesList[0]);
    }
  }

  private setCurrentSampleAndPlay(sample: Sample) {
    this.audioPlayer.audioReady$
      .pipe(
        take(1),
        tap(() => {
          this.audioPlayer.onPlaySample();
        })
      )
      .subscribe();

    this.storeService.setStore((store: Store) => {
      store.currentSample = sample;
    }, EWebStoreActions.SET_CURRENT_SAMPLE);
  }
}
