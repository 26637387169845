import { Component, Injector, Input, OnDestroy } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MobileSampleMenuBottomSheetComponent } from '../mobile-sample-menu-bottom-sheet/mobile-sample-menu-bottom-sheet.component';
import { IMobileMenuBottomSheetConfig } from '@common/interfaces';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ESampleGeneratedBy } from '@common/enums';
import { AiService } from '@common/core/services/api/ai.service';
import { tap } from 'rxjs/operators';
import { SuperSampleButtonComponent } from '../super-sample-button.component';

@Component({
  selector: 'ss-sample-menu-button',
  templateUrl: './sample-menu-button.component.html',
  styleUrls: ['./sample-menu-button.component.scss'],
})
export class SampleMenuButtonComponent extends SuperSampleButtonComponent implements OnDestroy {
  @Input() shouldShowViewPack = true;
  @Input() mobileMenuBottomSheetConfig: IMobileMenuBottomSheetConfig;
  protected matBottomSheet: MatBottomSheet;
  private subscription = new Subscription();

  eSampleGeneratedBy = ESampleGeneratedBy;

  constructor(protected injector: Injector, private router: Router, private aiService: AiService) {
    super(injector);
    this.matBottomSheet = injector.get(MatBottomSheet);
  }

  onViewPack() {
    this.router.navigate(['/', 'packs', this.sample.packId, 'search']);
    this.actionCompleted.emit();
  }

  onOpenMenuMobile() {
    const ref = this.matBottomSheet.open(MobileSampleMenuBottomSheetComponent, {
      data: {
        sample: this.sample,
        collection: this.collection,
        config: this.mobileMenuBottomSheetConfig,
      },
      autoFocus: false,
    });
    this.subscription.add(ref.instance.viewPack.subscribe(() => this.actionCompleted.emit()));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
