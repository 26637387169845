import { Injectable, Injector } from '@angular/core';

import { BitfAppSessionService } from '@bitf/services/app-session/bitf-app-session.service';
import { BitfLogDnaSenderService } from '@bitf/services/logger/bitf-log-dna-sender.service';
import { BitfPwaService } from '@bitf/services/pwa/bitf-pwa.service';
import { AiStatusService, ResponsiveService } from '@services';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { ESignal } from '@web/enums';

@Injectable({
  providedIn: 'root',
})
export class AppSessionService extends BitfAppSessionService {
  signal$ = new Subject<{ action: ESignal; payload: any }>();

  constructor(
    private bitfLogDnaSenderService: BitfLogDnaSenderService,
    private aiStatusService: AiStatusService,
    private responsiveService: ResponsiveService,
    protected bitfPwaService: BitfPwaService,
    protected injector: Injector
  ) {
    super(injector);
  }

  async init() {
    await super.init();
    // NOTE: At this point translations are already loaded

    // NOTE: needed for mobile/desktop routing support
    // initDynamicLayoutUrl(this.router, this.storeService);

    this.initBreakpointListener();
    this.responsiveService.init();

    // this.bitfTagManagerService.init();

    this.bitfLogDnaSenderService.init();

    // this.bitfMetadataService.setDefaultDescription();

    // PWA
    this.bitfPwaService.initOnlineChecker();
    // this.bitfPwaService.showDefaultOnlineStateChangeToast();
    if (environment.registerServiceWorker) {
      this.bitfPwaService.init();

      this.bitfPwaService.initManifest();

      this.bitfPwaService.initSwUpdate();
      this.bitfPwaService.handleSwUpdate();

      this.bitfPwaService.initBeforeInstallPrompt();
      this.bitfPwaService.checkForBeforeInstallPromptEvent();
    }

    this.aiStatusService.init();
  }

  initLogSender() {
    console.log('initLogSender, Implement me');
  }
}
