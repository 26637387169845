import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'bitf-in-view',
  templateUrl: './in-view.component.html',
  styleUrls: ['./in-view.component.scss'],
  exportAs: 'isInView',
})
export class InViewComponent implements OnInit, OnDestroy {
  @Input() options?: IntersectionObserverInit;
  @ViewChild('anchor', { static: true }) anchorEl: ElementRef<any>;
  isInView = false;

  observer: IntersectionObserver;

  ngOnInit(): void {
    const root = document.querySelector('.sidenav-content-page') || document.body;
    const options: IntersectionObserverInit = {
      root,
      rootMargin: '0px 0px 0px 0px',
      threshold: 0,
      ...this.options,
    };
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.isInView = true;
          this.removeObserver();
        }
      });
    }, options);
    this.observer.observe(this.anchorEl.nativeElement);
  }

  private removeObserver() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  ngOnDestroy() {
    this.removeObserver();
  }
}
