import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { AudioPlayerComponent } from '../audio-player/audio-player.component';
import { IMobileMenuBottomSheetConfig } from '@common/interfaces';
import { Router } from '@angular/router';

@Component({
  selector: 'ss-mobile-audio-player',
  templateUrl: './mobile-audio-player.component.html',
  styleUrls: ['./mobile-audio-player.component.scss'],
})
export class MobileAudioPlayerComponent extends AudioPlayerComponent {
  @Input() isPlayerExpanded = false;
  @Output() isPlayerExpandedChange = new EventEmitter<boolean>();
  mobileMenuBottomSheetConfig: IMobileMenuBottomSheetConfig = {
    shouldShowCollection: true,
  };

  constructor(injector: Injector, private router: Router) {
    super(injector);
    this.updateViewPackVisibility();
  }

  onCloseExtendedPlayer() {
    this.isPlayerExpanded = false;
    this.isPlayerExpandedChange.emit(false);
  }
  onOpenExtendedPlayer() {
    this.isPlayerExpanded = true;
    this.isPlayerExpandedChange.emit(true);
    this.updateViewPackVisibility();
  }

  private updateViewPackVisibility() {
    // If we are in mobile we've to remove the view pack button if we are inside a pack
    if (this.router.url.includes('/packs')) {
      this.mobileMenuBottomSheetConfig.shouldShowViewPack = false;
    } else {
      this.mobileMenuBottomSheetConfig.shouldShowViewPack = true;
    }
  }
}
