<div class="d-flex flex-column align-items-center message-container w-100">
  <div class="p-3 text-center">
    <div class="mat-headline-2">Whoops!</div>
    The page you're after seems to be on a coffee break.
    <br>
    Head back to the homepage and give it another
    shot!
  </div>
  <img class="w-100 mt-5"
    src="/assets/images/not_found_background.svg" />
</div>