<div class="h-100 sidenav-sm p-3">
  <button mat-icon-button
    class="d-block ml-auto"
    (click)="onCloseSidenav()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="pt-3"
    [innerHTML]="message">
  </div>
</div>