export enum EAiGenerationMessageStatus {
  IDLE = 'IDLE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

export enum EAiGenerationMessageType {
  AI_GENERATION_JOB = 'AI_GENERATION_JOB',
  AI_GENERATION_TASK = 'AI_GENERATION_TASK',
}

export enum EAiGenerationType {
  GENERATION = 'GENERATION',
  VARIATION = 'VARIATION',
}
