import { EAiGenerationMessageStatus, EAiGenerationMessageType } from '@common/enums';
import { IAiGenerationMessageData } from '@common/interfaces';

type Message = { [key in EAiGenerationMessageType]: IAiGenerationMessageData };

export class AiGenerationState {
  messages: Message = {} as Message;

  get jobMessage(): IAiGenerationMessageData {
    return this.messages[EAiGenerationMessageType.AI_GENERATION_JOB];
  }

  get taskMessage(): IAiGenerationMessageData {
    return this.messages[EAiGenerationMessageType.AI_GENERATION_TASK];
  }

  // This is the creation of new generation process (generate or variation)
  get isJobInProgress(): boolean {
    if (!this.jobMessage) {
      return false;
    }
    return this.jobMessage.status === EAiGenerationMessageStatus.IN_PROGRESS;
  }

  // This is the creation of every single task
  get isTaskInProgress(): boolean {
    if (!this.taskMessage) {
      return false;
    }
    return this.taskMessage.status === EAiGenerationMessageStatus.IN_PROGRESS;
  }

  constructor() {}

  reset() {
    this.messages = {} as Message;
  }
}
