import { Injectable, Injector } from '@angular/core';

import { BitfStoreService } from '@bitf/services/store/bitf-store.service';

import { Collection, Store } from '@models';
import { CollectionsService } from './api';
import { EBitfApiSortDirection, EBitfPQueryComparators } from '@common/enums';
import { eStoreActions } from '@web/enums';
// import { StoreStorageService } from '@services';

@Injectable({
  providedIn: 'root',
})

// NOTE: if you use storeStorage, the initalData must defined in the storeStorage, otherwise here
// NOTE: inital data must be an object literal to be passed in the storeClass constructor not a storeClass
// instance this is to avoid to create something like new storeClass(justClone(StoreClassInstance));
// which will lead to problems
export class StoreService extends BitfStoreService<Store> {
  private collectionsService: CollectionsService;
  constructor(injector: Injector) {
    // constructor(storeStorageService: StoreStorageService) {
    super({ storeClass: Store });
    // NOTE: this is to avoid circular dependency error
    setTimeout(() => {
      this.collectionsService = injector.get(CollectionsService);
    }, 0);
    // super({ storeClass: Store, storage: storeStorageService });
    // NOTE: version without storage and inital value here
    // super({ storeClass: Store, initialData: { prop1: 'Initial store value' } });
  }

  setPinnedCollections() {
    this.collectionsService
      .search<Collection>({
        sorting: [{ property: 'createdOn', direction: EBitfApiSortDirection.DESC }],
        query: [{ name: 'isPinned', value: true, comparator: EBitfPQueryComparators.EQUAL, operator: 0 }],
        size: 100,
        page: 1,
      })
      .subscribe(response => {
        this.setStore(store => {
          store.pinnedCollections = response.content;
        }, eStoreActions.SET_PINNED_COLLECTIONS);
      });
  }
}
