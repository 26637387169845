export enum ESampleGeneratedBy {
  HUMAN = 'HUMAN',
  AI = 'AI',
}

export enum ESampleFileType {
  FLAC = 'FLAC',
  WAV = 'WAV',
  MP3 = 'MP3',
}

export enum ESampleType {
  LOOP = 'LOOP',
  ONE_SHOT = 'ONE SHOT',
  ALL = 'ALL',
}

export enum ESampleKey {
  A = 'A',
  A_SHARP = 'A#',
  B = 'B',
  C = 'C',
  C_SHARP = 'C#',
  D = 'D',
  D_SHARP = 'D#',
  E = 'E',
  F = 'F',
  F_SHARP = 'F#',
  G = 'G',
  G_SHARP = 'G#',
  ALL = 'ALL',
}

export enum ESampleScale {
  MAJOR = 'MAJOR',
  MINOR = 'MINOR',
  ALL = 'ALL',
}

export enum ESampleCustomSort {
  RECENT = 'createdOn',
  BPM = 'bpm',
  RANDOM = 'RANDOM',
  POPULAR = 'POPULAR',
  GENERATED = 'generatedOn',
}

export enum EDialogResponseDataType {
  OPEN_CREATE_COLLECTION_DIALOG = 'OPEN_CREATE_COLLECTION_DIALOG',
}

export enum ESubscriptionDuration {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  TRIAL = 'TRIAL',
}

export enum ESubscriptionType {
  FREE = 'FREE',
  BASIC = 'BASIC',
  PREMIUM = 'PREMIUM',
  PLATINUM = 'PLATINUM',
}

export enum ESubscriptionStatus {
  ACTIVE = 'ACTIVE',
  ENDING = 'ENDING',
  UPGRADING = 'UPGRADING',
  DOWNGRADING = 'DOWNGRADING',
  PAYMENT_ERRORS = 'PAYMENT_ERRORS',
  SWITCHING = 'SWITCHING',
  PROCESSING = 'PROCESSING',
}

export enum ESignal {
  SAMPLE_REMOVED_FROM_COLLECTION = 'SAMPLE_REMOVED_FROM_COLLECTION',
  SAMPLE_REMOVED_FROM_FAVORITES = 'SAMPLE_REMOVED_FROM_FAVORITES',
}

export enum EAiFeedback {
  LIKE = 'LIKE',
  DISLIKE = 'DISLIKE',
}
