import { Component, Input } from '@angular/core';
import { Sample, Store } from '@common/core/models';
import { EWebStoreActions } from '@web/enums';
import { take, tap } from 'rxjs/operators';
import { StoreService } from '@web/core/services';

@Component({
  selector: 'ss-audio-button',
  templateUrl: './audio-button.component.html',
  styleUrls: ['./audio-button.component.scss'],
})
export class AudioButtonComponent {
  @Input() sample: Sample;
  @Input() displayAsFab = false;
  get audioPlayer() {
    return this.storeService.store.audioPlayer;
  }
  get currentSample() {
    return this.storeService.store.currentSample;
  }
  get isPlaying(): boolean {
    if (!this.currentSample || !this.audioPlayer) {
      return false;
    }
    return this.currentSample === this.sample && this.audioPlayer.isPlaying;
  }

  constructor(private storeService: StoreService) {}

  onPlaySample($event) {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.currentSample !== this.sample) {
      // Register for audio player audioReady event
      this.audioPlayer.audioReady$
        .pipe(
          take(1),
          tap(() => {
            this.audioPlayer.onPlaySample();
          })
        )
        .subscribe();

      // Note set the current sample in the store
      this.storeService.setStore((store: Store) => {
        store.currentSample = this.sample;
      }, EWebStoreActions.SET_CURRENT_SAMPLE);
    } else {
      this.audioPlayer.waveSurfer.seekTo(0);
      this.audioPlayer.onPlaySample();
    }
  }

  onPauseSample($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.audioPlayer.onPauseSample();
  }
}
