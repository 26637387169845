import { Pack } from './pack.model';
import { SuperModel } from './super-model.model';

export class HomeSettings extends SuperModel {
  banner?: { bannerCoverUrl: string; pack?: Pack };

  constructor(data = {}) {
    super(data);
    if (this.banner?.pack) {
      this.banner.pack = new Pack(this.banner.pack);
    }
  }

  get serialised() {
    return {};
  }
}
