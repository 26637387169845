<div class="d-flex flex-column align-items-center message-container w-100">
  <div class="p-3 text-center">
    <div class="mat-headline-2">Uh-oh</div>
    It seems there were no results found based on your search. <br><br>
    Try removing some filters to increase the chances
    <br>
    of obtaining a more extensive list of results.
  </div>
  <img class="w-100 mt-5"
    src="/assets/images/not_found_background.svg" />
</div>