import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Collection, Sample } from '@common/core/models';
import { IBitfApiPagination } from '@common/interfaces';
import { EApiCallStateNames } from '@web/enums';

@Component({
  selector: 'ss-search-samples-result-manager',
  templateUrl: './search-samples-result-manager.component.html',
  styleUrls: ['./search-samples-result-manager.component.scss'],
})
export class SearchSamplesResultManagerComponent {
  @Input() apiCallStateName: EApiCallStateNames;
  @Input() samples: Sample[];
  @Input() collection: Collection;
  @Input() pagination: IBitfApiPagination;
  @Input() isLoading: boolean;
  @Input() isFirstSearchDone: boolean;
  @Input() shouldShowAddToFavorite = true;
  @Input() shouldShowViewPack = true;
  @Input() shouldShowAiFeedback = false;
  @Input() shouldShowRefreshListButton = false;
  @Input() showSorting = true;
  @Input() useWelcomePage = false;
  @Input() isLoadingMessage = '';

  @Output() refreshList = new EventEmitter();

  get shouldShowTable(): boolean {
    return !!this.samples?.length;
  }

  get shouldShowInlineLoader(): boolean {
    return this.isLoading && !this.shouldShowTable;
  }

  get shouldShowNotFound(): boolean {
    return !this.useWelcomePage && this.shouldLoadWelcomeOrNotFound;
  }

  get shouldShowWelcomePage(): boolean {
    return this.useWelcomePage && this.shouldLoadWelcomeOrNotFound;
  }

  get shouldLoadWelcomeOrNotFound(): boolean {
    return this.isFirstSearchDone && !this.isLoading && !this.samples.length;
  }
}
