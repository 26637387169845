import { Injectable } from '@angular/core';
import { AuthService, StoreService, ToastMessagesService, UsersService } from '.';
import {
  EAiGenerationMessageStatus,
  EAiGenerationMessageType,
  EBitfUiMessageType,
  eStoreActions,
  EWebStoreActions,
} from '@web/enums';
import {
  IAiGenerationMessage,
  IAiGenerationMessageData,
  IBitfApiResponse,
  IBitfToastData,
} from '@web/interfaces';
import { BitfWebsocketService } from '@bitf/core/services/sockets/websockets/bitf-websockets.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User } from '../models';

@Injectable({ providedIn: 'root' })
export class AiStatusService {
  private connectionInitStarted = false;

  constructor(
    private storeService: StoreService,
    private toastMessages: ToastMessagesService,
    private authService: AuthService,
    private bitfWebsocketService: BitfWebsocketService<IAiGenerationMessage>,
    private router: Router,
    private usersService: UsersService
  ) {}

  init() {
    this.initSocketConnection();
  }

  private initSocketConnection() {
    if (this.authService.authTokenMetaData?.token) {
      this.startSocketConnection();
      return;
    }

    const subscription = this.authService.authState$.subscribe(authState => {
      if (this.authService.authTokenMetaData?.token && !this.connectionInitStarted) {
        this.startSocketConnection();
        setTimeout(() => subscription.unsubscribe());
      }
    });
  }

  private startSocketConnection() {
    this.connectionInitStarted = true;
    this.bitfWebsocketService
      .createObservableSocket()
      .pipe(
        tap(message => {
          switch (message.messageType) {
            case EAiGenerationMessageType.AI_GENERATION_JOB:
              this.parseJobMessage(message.data);
              break;
            case EAiGenerationMessageType.AI_GENERATION_TASK:
              this.parseTaskMessage(message.data);
              break;
          }
        })
      )
      .subscribe();

    // Ask to the server for the current job status
    this.bitfWebsocketService.send({ action: 'getAiGenerationJob' });
  }

  private parseJobMessage(messageData: IAiGenerationMessageData) {
    switch (messageData.status) {
      case EAiGenerationMessageStatus.IN_PROGRESS:
        this.storeService.setStore(() => {}, eStoreActions.AI_GENERATION_IN_PROGRESS);
        break;
      case EAiGenerationMessageStatus.ERROR:
        this.storeService.setStore(() => {}, eStoreActions.AI_GENERATION_ERROR);
        this.toastMessages.show({
          title: '',
          type: EBitfUiMessageType.ERROR,
          message: 'Error generating samples',
        });
        break;
      case EAiGenerationMessageStatus.COMPLETED:
        this.storeService.setStore(() => {}, eStoreActions.AI_GENERATION_COMPLETED);
        this.showSamplesGeneratedToastMessage();
        this.usersService
          .getMe()
          .pipe(
            tap((response: IBitfApiResponse<User>) => {
              this.storeService.setStore(store => {
                store.user = response.content;
              }, EWebStoreActions.UPDATE_USER);
            })
          )
          .subscribe();
        break;
    }

    this.storeService.setStore(store => {
      store.aiGenerationState.messages[EAiGenerationMessageType.AI_GENERATION_JOB] = messageData;
    }, eStoreActions.AI_GENERATION_STATE_UPDATE);
  }

  private parseTaskMessage(messageData: IAiGenerationMessageData) {
    this.storeService.setStore(store => {
      store.aiGenerationState.messages[EAiGenerationMessageType.AI_GENERATION_TASK] = messageData;
    }, eStoreActions.AI_GENERATION_STATE_UPDATE);
  }

  private showSamplesGeneratedToastMessage() {
    const toastMessage: IBitfToastData = {
      title: '',
      message: 'New samples generated',
      type: EBitfUiMessageType.SUCCESS,
    };

    if (!this.router.url.includes('/ai/generate')) {
      toastMessage.link = {
        label: 'View',
        url: ['/', 'ai', 'generate'],
        queryParams: { reload: `${Date.now()}` },
      };
    } else {
      toastMessage.link = {
        label: 'Reload',
        url: ['/', 'ai', 'generate'],
        queryParams: { reload: `${Date.now()}` },
      };
    }

    this.toastMessages.show(toastMessage);
    // }
  }
}
