<div class="row justify-content-between flex-nowrap mb-3">
  <form class="form col-auto flex-nowrap col col-md-16 row align-items-center flex-shrink-1"
    (submit)="onSearch()">

    <mat-form-field class="form-field-condensed form-density-1 col-auto flex-grow-1"
      appearance="outline">
      <mat-label>{{label}}</mat-label>
      <input matInput
        data-testid="search-input"
        (keydown.enter)="onSearch()"
        aria-label="Search"
        [matAutocomplete]="autocomplete"
        [formControl]="promptControl"
        [(ngModel)]="promptValue"
        placeholder="Instrument genre label mpm key scale"
        #autoCompleteInput>

      <button mat-icon-button
        matSuffix
        data-testid="search-reset-button"
        class="d-none d-md-block"
        (click)="onClearPrompt()"
        *ngIf="promptControl.value">
        <mat-icon>close</mat-icon>
      </button>

      <button mat-icon-button
        matSuffix
        data-testid="search-icon-button"
        class="d-block d-md-none"
        (click)="onSearch()">
        <mat-icon>search</mat-icon>
      </button>

      <mat-autocomplete autoActiveFirstOption
        #autocomplete="matAutocomplete"
        [displayWith]="displayFn">
        <mat-option *ngFor="let tag of tags | async"
          (click)="onSearch()"
          [value]="tag">
          <span>{{tag.label}}</span>
          <small *ngIf="tag?.category"> - {{tag?.category}}</small>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="col-auto p-0 d-none d-md-block">
      <button mat-fab
        extended
        data-testid="search-submit-button"
        color="primary"
        class="ml-1"
        type="submit">Search</button>
    </div>
  </form>

  <div class="col-auto pl-0 d-flex align-items-center">
    <button mat-fab
      data-testid="filter-mobile-button"
      *ngIf="isHandsetLayout"
      [ngClass]="{'border-light border-solid': !filtersRequestPart.isFilterActive}"
      [color]="filtersRequestPart.isFilterActive ? 'accent' : 'basic'"
      (click)="onOpenFilter()">
      <mat-icon>tune</mat-icon>
    </button>

    <button mat-fab
      data-testid="filter-desktop-button"
      *ngIf="!isHandsetLayout"
      [ngClass]="{'border-light border-solid': !filtersRequestPart.isFilterActive}"
      extended
      [color]="filtersRequestPart.isFilterActive ? 'accent' : 'basic'"
      (click)="onOpenFilter()">
      <mat-icon>tune</mat-icon>
      Filters
    </button>
  </div>
</div>