<div *ngIf="hasChildren">
  <div class="d-flex flex-wrap my-3 mx-n1"
    data-testid="first-level-tags">
    <ng-container *ngFor="let tag of tags;let even = even">
      <ss-tag class="col-12 mb-2 px-1"
        cssClasses="w-100 big"
        [tag]="tag"
        [filters]="filters"
        (change)="onTagChange()"></ss-tag>
    </ng-container>
  </div>

  <div *ngFor="let tag of tags">
    <div *ngIf="tag.children.length"
      class="mb-3">
      <h3 class="mb-1 parent-tag-title">{{tag.label}}</h3>

      <div class="d-flex flex-wrap">
        <ss-tag class="mb-2 mr-2"
          *ngFor="let childTag of tag.children"
          [tag]="childTag"
          [filters]="filters"
          (change)="onTagChange()"></ss-tag>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!hasChildren"
  class="d-flex flex-wrap">
  <ss-tag class="mb-2 mr-2"
    *ngFor="let tag of tags"
    [tag]="tag"
    [filters]="filters"
    (change)="onTagChange()"></ss-tag>
</div>