<div *ngIf="!isLoading"
  class="overflow-hidden">
  <h1 class="text-active">Hi {{storeService.store.user?.fullName}}</h1>

  <div *ngFor="let suggestedPack of suggestedPacks;"
    class="mb-4">
    <h2 class="mb-2 text-active text-capitalize">{{suggestedPack?.genre?.label}}</h2>
    <h3 class="mb-3">{{suggestedPack?.genre?.description}}</h3>

    <div class="d-flex flex-nowrap w-100 packs-container pr-3 pb-1">
      <ss-item-card class="col-11 col-sm-5 col-md-4 col-lg-3 pl-0 pr-3"
        *ngFor="let pack of suggestedPack.packs; trackBy:trackByFn"
        [title]="pack.label"
        [subTitle]="pack.author"
        [imageUrl]="pack.coverUrl"
        [linkUrl]="'/packs/' + pack.id + '/search'"
        [sample]="pack.sample"></ss-item-card>
    </div>
  </div>
</div>

<ss-inline-loader *ngIf="isLoading"></ss-inline-loader>