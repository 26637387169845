import { Component, Injector, Input } from '@angular/core';
import { AiGenerationSettingsDialogComponent } from '@web/shared/ai-generation-settings-dialog/ai-generation-settings-dialog.component';
import { SuperSampleButtonComponent } from '../super-sample-button.component';
import { IBitfCloseEvent } from '@web/interfaces';
import { EBitfCloseEventStatus, EBitfUiMessageType } from '@common/enums';
import { StoreService, ToastMessagesService } from '@web/core/services';

@Component({
  selector: 'ss-sample-generate-variation-sound-button',
  templateUrl: './sample-generate-variation-sound-button.component.html',
  styleUrls: ['./sample-generate-variation-sound-button.component.scss'],
})
export class SampleGenerateVariationSoundButtonComponent extends SuperSampleButtonComponent {
  constructor(
    protected injector: Injector,
    private toastMessagesService: ToastMessagesService,
    protected storeService: StoreService
  ) {
    super(injector);
  }

  openViewPromptDialog() {
    if (this.storeService.store.aiGenerationState.isJobInProgress) {
      this.toastMessagesService.show({
        title: '',
        message: 'Sample generation already in progress, please wait',
        type: EBitfUiMessageType.SUCCESS,
      });
      return;
    }

    this.dialogsService.dialog
      .open(AiGenerationSettingsDialogComponent, {
        width: '420px',
        maxWidth: this.isHandsetLayout ? '95%' : '90%',
        height: 'auto',
        maxHeight: this.isHandsetLayout ? '100%' : '70%',
        data: {
          sample: this.sample,
          title: 'Generate Variation',
          isEditMode: true,
        },
      })
      .afterClosed()
      .subscribe((result: IBitfCloseEvent<string>) => {
        if (result?.status === EBitfCloseEventStatus.OK) {
          this.beforeAction.emit(true);
        }
      });
  }
}
