import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { StoreService } from '@common/core/services';
import { ESubscriptionType } from '@common/enums/common.enum';

@Component({
  selector: 'ss-subscription-banner',
  templateUrl: './subscription-banner.component.html',
  styleUrls: ['./subscription-banner.component.scss'],
})
export class SubscriptionBannerComponent {
  @Input() coverUrl: string = 'assets/images/tmp-banner.webp';

  constructor(private storeService: StoreService, private router: Router) {}

  get showBanner() {
    return this.storeService.store.user?.subscription?.showFreeTrialBanner;
  }

  onBannerClick() {
    this.router.navigate(['/users', 'subscriptions']);
  }
}
