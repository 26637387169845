import { Component, Injector } from '@angular/core';
import { AiGenerationSettingsDialogComponent } from '@web/shared/ai-generation-settings-dialog/ai-generation-settings-dialog.component';
import { SuperSampleButtonComponent } from '../super-sample-button.component';

@Component({
  selector: 'ss-sample-ai-view-prompt-button',
  templateUrl: './sample-ai-view-prompt-button.component.html',
  styleUrls: ['./sample-ai-view-prompt-button.component.scss'],
})
export class SampleAiViewPromptButtonComponent extends SuperSampleButtonComponent {
  constructor(protected injector: Injector) {
    super(injector);
  }

  openViewPromptDialog() {
    this.dialogsService.dialog
      .open(AiGenerationSettingsDialogComponent, {
        width: '390px',
        maxWidth: this.isHandsetLayout ? '95%' : '90%',
        height: 'auto',
        maxHeight: this.isHandsetLayout ? '100%' : '70%',
        data: {
          sample: this.sample,
          title: 'Prompt',
          isEditMode: false,
        },
      })
      .afterClosed()
      .subscribe();
  }
}
