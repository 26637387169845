import { Component, Input } from '@angular/core';
import { EBitfApiSortDirection } from '@common/enums';
import { ESampleCustomSort } from '@common/enums/common.enum';
import { IBitfSortOption } from '@common/interfaces';
import { EApiCallStateNames } from '@web/enums';

@Component({
  selector: 'ss-search-samples-sorting',
  templateUrl: './search-samples-sorting.component.html',
  styleUrls: ['./search-samples-sorting.component.scss'],
})
export class SearchSamplesSortingComponent {
  @Input() apiCallStateName: EApiCallStateNames;

  sortOptions: IBitfSortOption[] = [
    {
      label: 'Most popular',
      value: {
        property: ESampleCustomSort.POPULAR,
        direction: EBitfApiSortDirection.DESC,
      },
    },
    {
      label: 'Most recent',
      value: {
        property: ESampleCustomSort.RECENT,
        direction: EBitfApiSortDirection.DESC,
      },
    },
    {
      label: 'Random',
      value: {
        property: ESampleCustomSort.RANDOM,
        direction: EBitfApiSortDirection.DESC,
      },
    },
    {
      label: 'Bpm',
      value: {
        property: ESampleCustomSort.BPM,
        direction: EBitfApiSortDirection.DESC,
      },
    },
  ];
}
