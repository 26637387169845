import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonSharedModule } from '@common/shared/common-shared.module';
import { MATERIAL_MODULES, CDK_MODULES } from './material-modules';

import { BitfLoaderComponent } from '@bitf/core/components/ui/loader/bitf-loader.component';
import { BitfMatPaginatorComponent } from '@bitf/core/components/ui/paginator/material/bitf-mat-paginator.component';
import { BitfUiRoleManagerDirective } from '@common/libs/bitforce/directives/ui-role-manager/bitf-ui-role-manager.directive';
import { BitfTouchEventDirective } from '@common/libs/bitforce/directives/bitf-touch-events/bitf-touch-event.directive';
import { BitfMatOkCancelDialogComponent } from '@common/libs/bitforce/core/components/ui/ok-cancel-dialog/material/bitf-mat-ok-cancel-dialog.component';
import { BitfMatLoaderComponent } from '@bitf/core/components/ui/loader/material/bitf-mat-loader/bitf-mat-loader.component';
import { BitfImageComponent } from '@common/libs/bitforce/core/components/ui/image/bitf-image/bitf-image.component';
import { DateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { DateAdapterService } from '@common/core/services';
import { FixedPlayerComponent } from './fixed-player/fixed-player.component';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { AudioWaveComponent } from './audio-wave/audio-wave.component';
import { BitfMatSearchComponent } from '@common/libs/bitforce/core/components/ui/search/material/bitf-mat-search.component';
import { BitfMatSortComponent } from '@common/libs/bitforce/core/components/ui/sort/material/bitf-mat-sort.component';
import { BitfFormItemContainerComponent } from '@common/libs/bitforce/core/components/ui/form-item/bitf-form-item-container/bitf-form-item-container.component';
import { BitfMatFormOptionComponent } from '@common/libs/bitforce/core/components/ui/form-item/material/mat-form-option/bitf-mat-form-option.component';
import { SearchSamplesSortingComponent } from './search-pages/search-samples-sorting/search-samples-sorting.component';
import { SearchSamplesFiltersComponent } from './search-pages/search-samples-filters/search-samples-filters.component';
import { SearchSamplesTableComponent } from './search-pages/search-samples-table/search-samples-table.component';
import { SearchSamplesNotFoundComponent } from './search-pages/search-samples-not-found/search-samples-not-found.component';
import { SearchSamplesSearchBarComponent } from './search-pages/search-samples-search-bar/search-samples-search-bar.component';
import { BitfMatFormItemComponent } from '@common/libs/bitforce/core/components/ui/form-item/material/mat-form-item/bitf-mat-form-item.component';
import { SampleDownloadButtonComponent } from './sample-buttons/sample-download-button/sample-download-button.component';
import { SampleFavoriteButtonComponent } from './sample-buttons/sample-favorite-button/sample-favorite-button.component';
import { SampleCollectionButtonComponent } from './sample-buttons/sample-collection-button/sample-collection-button.component';
import { SampleMenuButtonComponent } from './sample-buttons/sample-menu-button/sample-menu-button.component';
import { SampleBuyButtonComponent } from './sample-buttons/sample-buy-button/sample-buy-button.component';
import { ItemCardComponent } from './item-card/item-card.component';
import { UserSuggestedPacksComponent } from './user-suggested-packs/user-suggested-packs.component';
import { SearchSamplesResultManagerComponent } from './search-pages/search-samples-result-manager/search-samples-result-manager.component';
import { InlineLoaderComponent } from './inline-loader/inline-loader.component';
import { SampleAddOrCreateCollectionDialogComponent } from './sample-add-or-create-collection-dialog/sample-add-or-create-collection-dialog.component';
import { CreateUpdateCollectionDialogComponent } from './create-update-collection-dialog/create-update-collection-dialog.component';
import { SampleCoverComponent } from './sample-cover/sample-cover.component';
import { AudioButtonComponent } from './audio-button/audio-button.component';
import { SearchSamplesActiveTagsComponent } from './search-pages/search-samples-active-tags/search-samples-active-tags.component';
import { SearchSamplesKeyComponent } from './search-pages/search-samples-key/search-samples-key.component';
import { SearchSamplesBpmComponent } from './search-pages/search-samples-bpm/search-samples-bpm.component';
import { SearchSamplesTagsSelectorComponent } from './search-pages/search-samples-tags-selector/search-samples-tags-selector.component';
import { TagComponent } from './tag/tag.component';
import { SearchSamplesTypeComponent } from './search-pages/search-samples-type/search-samples-type.component';
import { SearchSamplesSuperFilterComponent } from './search-pages/search-samples-super-filter/search-samples-super-filter.component';
import { SampleTitleAndTagsComponent } from './sample-title-and-tags/sample-title-and-tags.component';
import { MobileAudioPlayerComponent } from './mobile-audio-player/mobile-audio-player.component';
import { DesktopAudioPlayerComponent } from './desktop-audio-player/desktop-audio-player.component';
import { HeaderCoverComponent } from './header-cover/header-cover.component';
import { HeaderSearchComponent } from './header-search/header-search.component';
import { DesktopFiltersSidenavComponent } from './search-pages/desktop-filters-sidenav/desktop-filters-sidenav.component';
import { InViewComponent } from '@common/libs/bitforce/core/components/ui/in-view/in-view.component';
import { PreferredGenresComponent } from './preferred-genres/preferred-genres.component';
import { MobileFiltersSidenavComponent } from './search-pages/mobile-filters-sidenav/mobile-filters-sidenav.component';
import { MobileSampleMenuBottomSheetComponent } from './sample-buttons/mobile-sample-menu-bottom-sheet/mobile-sample-menu-bottom-sheet.component';
import { MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from '@angular/material/bottom-sheet';
import { SearchSamplesKeyButtonComponent } from './search-pages/search-samples-key/search-samples-key-button/search-samples-key-button.component';
import { SampleVolumeComponent } from './sample-volume/sample-volume.component';
import { NotificationBarComponent } from './notification-bar/notification-bar.component';
import { SubscriptionBannerComponent } from './subscription-banner/subscription-banner.component';
import { InfoSidePanelComponent } from './open-side-panel-button/info-side-panel/info-side-panel.component';
import { OpenSidePanelButtonComponent } from './open-side-panel-button/open-side-panel-button.component';
import { SampleFeedbackButtonComponent } from './sample-buttons/sample-feedback-button/sample-feedback-button.component';
import { SampleRegenerateSoundButtonComponent } from './sample-buttons/sample-regenerate-sound-button/sample-regenerate-sound-button.component';
import { AiGenerationSettingsDialogComponent } from './ai-generation-settings-dialog/ai-generation-settings-dialog.component';
import { SampleAiViewPromptButtonComponent } from './sample-buttons/sample-ai-view-prompt-button/sample-ai-view-prompt-button.component';
import { SampleGenerateVariationSoundButtonComponent } from './sample-buttons/sample-generate-variation-sound-button/sample-generate-variation-sound-button.component';

const SHARED_MODULES = [CommonSharedModule, RouterModule, MATERIAL_MODULES, CDK_MODULES];
const SHARED_COMPONENTS = [
  BitfLoaderComponent,
  BitfMatPaginatorComponent,
  BitfUiRoleManagerDirective,
  BitfTouchEventDirective,
  BitfMatOkCancelDialogComponent,
  BitfMatLoaderComponent,
  BitfImageComponent,
  FixedPlayerComponent,
  AudioPlayerComponent,
  AudioWaveComponent,
  BitfMatSearchComponent,
  BitfMatSortComponent,
  BitfFormItemContainerComponent,
  BitfMatFormOptionComponent,
  SearchSamplesSortingComponent,
  SearchSamplesFiltersComponent,
  SearchSamplesTableComponent,
  SearchSamplesNotFoundComponent,
  SearchSamplesSearchBarComponent,
  BitfMatFormItemComponent,
  SampleDownloadButtonComponent,
  SampleFavoriteButtonComponent,
  SampleCollectionButtonComponent,
  SampleMenuButtonComponent,
  SampleBuyButtonComponent,
  ItemCardComponent,
  UserSuggestedPacksComponent,
  SearchSamplesResultManagerComponent,
  InlineLoaderComponent,
  SampleAddOrCreateCollectionDialogComponent,
  CreateUpdateCollectionDialogComponent,
  SampleCoverComponent,
  AudioButtonComponent,
  SearchSamplesActiveTagsComponent,
  SearchSamplesKeyComponent,
  SearchSamplesBpmComponent,
  SearchSamplesTagsSelectorComponent,
  TagComponent,
  SearchSamplesTypeComponent,
  SearchSamplesSuperFilterComponent,
  SampleTitleAndTagsComponent,
  MobileAudioPlayerComponent,
  DesktopAudioPlayerComponent,
  HeaderCoverComponent,
  HeaderSearchComponent,
  DesktopFiltersSidenavComponent,
  InViewComponent,
  PreferredGenresComponent,
  MobileFiltersSidenavComponent,
  MobileSampleMenuBottomSheetComponent,
  SearchSamplesKeyButtonComponent,
  SampleVolumeComponent,
  NotificationBarComponent,
  SubscriptionBannerComponent,
  InfoSidePanelComponent,
  OpenSidePanelButtonComponent,
  SampleFeedbackButtonComponent,
  SampleRegenerateSoundButtonComponent,
  AiGenerationSettingsDialogComponent,
  SampleAiViewPromptButtonComponent,
  SampleGenerateVariationSoundButtonComponent,
];

const SHARED_DIRECTIVES = [];
const PROVIDERS = [
  DatePipe,
  { provide: DateAdapter, useClass: DateAdapterService },
  { provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: { panelClass: 'bottom-sheet' } },
];

@NgModule({
  imports: [...SHARED_MODULES],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [...PROVIDERS],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
