<h2 mat-dialog-title>{{isInCreateMode? 'Create new collection' : 'Edit collection'}}
</h2>
<mat-dialog-content>
  <form *ngIf="form"
    (submit)="onOk()"
    [formGroup]="form"
    class="mt-4">
    <mat-form-field appearance="outline"
      class="w-100">
      <mat-label>Label</mat-label>
      <input matInput
        type="text"
        formControlName="label" />
    </mat-form-field>

    <mat-form-field appearance="outline"
      class="w-100">
      <mat-label>Description</mat-label>
      <textarea matInput
        formControlName="description"
        cdkTextareaAutosize
        cdkAutosizeMinRows="3"
        cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>

    <div class="d-flex mb-3">
      <div class="mr-3"
        *ngFor="let color of PIN_COLORS"
        (click)="(selectedColor !== color) ? selectedColor = color : selectedColor = undefined">
        <div class="dot {{'dot-'+color}}"
          [ngClass]="{'active': selectedColor === color}">

        </div>
      </div>
    </div>

    <mat-slide-toggle formControlName="isPinned">Pin this collection</mat-slide-toggle>
  </form>
</mat-dialog-content>

<mat-divider>
</mat-divider>
<mat-dialog-actions class="justify-content-end">
  <button mat-button
    (click)="onCancel()"
    type="button">
    Cancel
  </button>

  <button mat-raised-button
    [disabled]="form.invalid"
    color="primary"
    (click)="onOk()"
    class="ml-3">
    {{isInCreateMode? 'Create' : 'Save'}}
  </button>
</mat-dialog-actions>